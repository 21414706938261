.panel-housing-living {
  #housing-content-main {
    #housing-content-header,
    #housing-content-body,
    #housing-content-body-1,
    #housing-content-body-2,
    #housing-content-body-3,
    #housing-content-body-4 {
      .pane-title {
        padding-bottom: 1rem;
        font-size: 3rem;
      }
      .fieldable-panels-pane {
        img {
          padding-bottom: 2.5rem;
        }
      }
    }
    #housing-content-top-right,
    #housing-content-bottom-right,
    #housing-content-body-1-right,
    #housing-content-body-2-right,
    #housing-content-body-3-right,
    #housing-content-body-4-right {
      .panel-pane {
        ul {
          li {
            padding-bottom: 2rem;
          }
        }
      }
    }
  }
}
