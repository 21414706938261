/**
 * Hero 3 Column layout
 */
.panel-hero-3col {
  .hero-section {
    .pane-node-field-incoming-residents-image,
    .pane-node-field-current-residents-image {
      padding-bottom: 2rem;
    }
  }
  .main-content {
    padding-bottom: 4rem;
  }
  // housing options details begin
  .pane-housing-options-panel-pane-2 {
    .views-field-nothing {
      .housing-details {
        .row {
          margin: 0;
          div.col-md-6 {
            padding: 0;
            &:first-child {
              @include breakpoint($md) {
                border-right: 1px solid #eee;
              }
            }
          }
          .housing-info-1 {
            position: relative;
            &:after {
              content: '';
              background: #eee;
              position: absolute;
              bottom: 0;
              left: 0%;
              width: 95%;
              height: 1px;
            }
            .housing-amenities {
              //border-bottom: 1px solid #eee;
              //padding: 2rem 0 0 3rem;
              padding: 1rem 0 0 3rem;
              height: 100%;
              display: inline-block;
              clear: both;
              ul {
                // @include custom-multi-column(2);
                // @include custom-multi-gap;
                // list-style-position: inside;
                li {
                  position: relative;
                  float:left;
                  padding: 0 1rem 2rem 0.5rem;
                  width: 18rem;
                }
              }
            }
          }
          .housing-info-2{
            margin: 0 1.4rem 0 0;
            h3 {
              padding-bottom: 1.5rem
            }
            .housing-floorplan {
              img.file-icon {
                display: none;
                + a {
                  position: relative;
                  padding-left: 3.5rem;
                  &:before {
                    content: '';
                    position: absolute;
                    background: transparent url(../images/sprite/housing-icons.png) no-repeat;
                    background-position:  -273px, -2px;
                    background-size: 58rem;
                    width: 27px;
                    height: 27px;
                    opacity: 1;
                    left: 2px;
                    top: -1px;
                  }
                }
              }
            }
          }
          .housing-info-3 {
            position: relative;
            padding-bottom: 1rem;
            border-top: 1px solid #eee;
            margin-top: 2rem;
            @include breakpoint($md) {
              border-top: none;
              margin-top: 0;
            }
            &:after {
              content: '';
              background: #eee;
              position: absolute;
              bottom: 0;
              left: 5%;
              width: 90%;
              height: 1px;
            }
            h3 {
              padding-left: 0;
              padding-top: 0;
              @include breakpoint($md) {
                padding-left: 3rem;
              }
            }
            .housing-cost {
              position: relative;
              padding: 0 0 0 1rem;
              margin: 2rem 0 0 3rem;
              @include breakpoint($md) {
                margin: 2rem 0 0 5rem;
              }
            }
            .housing-resident-type {
              position: relative;
              padding: 0 0 0 1rem;
              margin: 3rem 0 0 3rem;
              @include breakpoint($md) {
                margin: 3rem 0 0 5rem;
              }
            }
          }
          .housing-info-4 {
            padding-left: 0;
            @include breakpoint($md) {
              padding-left: 3rem;
            }
            .housing-location {
              position: relative;
              margin: 2rem 0 0 3rem;
              padding: 0 0 0 1rem;
              cursor: pointer;
              @include breakpoint($md) {
                margin: 2rem 0 0 2rem;
              }
              &:hover {
                color: $wcm-bright-orange;
              }
              .icon-location {
                &:before {
                  top: -1px;
                }
              }
            }
          }
        }
      }
    }
    .views-field-field-housing-images {
      padding: 4rem 0 4rem 0;
    }
    #housing-image-slideshow {
      z-index: 0;
      background-color: #f7f7f7;
      width: 100% !important;
      height: 26rem;
      @include breakpoint($md){
        height: 36rem;
      }
      @include breakpoint($md){
        height: 46.5rem;
      }
      @include breakpoint($lg){
        height: 56.5rem;
      }
      .housing-slideshow-item {
        //position: relative !important;
        text-align: center;
        width: 100% !important;
        margin: 0;
        .housing-slide-image {
          margin: 0;
          padding: 0;
          background-color: #f7f7f7;
          > img {
            width: auto;
            max-height: 56.5rem;
            height: 26rem;
            @include breakpoint($md){
              height: 36rem;
            }
            @include breakpoint($md){
              height: 46.5rem;
            }
            @include breakpoint($lg){
              height: 56.5rem;
            }
          }
        }
        .housing-slide-text {
          position: absolute;
          bottom: 0rem;
          width: 100%;
          background: #000;
          opacity: 0.65;
          color: #fff;
          height: 9rem;
          .slide-caption {
            font-size: 1.3rem;
            text-align: left;
            width: inherit;
            height: inherit;
            display: table-cell;
            vertical-align: middle;
            padding: 0 2rem;
            @include breakpoint($md) {
              padding: 0 4rem;
            }
          }
        }
      }
    }
    #housing-image-control {
      position: relative;
      z-index: 1;
      .cycle-prev,
      .cycle-next {
        display: block;
        width: 3.1rem;
        opacity: 0.6;
        height: 26rem;
        @include breakpoint($md){
          height: 36rem;
        }
        @include breakpoint($md){
          height: 46.5rem;
        }
        @include breakpoint($lg){
          height: 56rem;
        }
      }
      .cycle-prev {
        position: absolute;
        bottom: 48%;
        left: 0;
        background: url(../images/arrow-left.png) no-repeat;
        background-position: 0% 48% !important;
        background-size: 4.5rem;
      }
      .cycle-next {
        position: absolute;
        bottom: 48%;
        right: 0;
        background: url(../images/arrow-right.png) no-repeat;
        background-position: 100% 48% !important;
        background-size: 4.5rem;
      }
      a {
        outline: none;
      }
    }
    @include custom-ul-list-style;
    + .pane-bundle-map {
      iframe {
        width: 100%;
      }
    }
  }
  .pane-housing-options-panel-pane-3 {
    form#views-exposed-form-housing-options-panel-pane-3 {
      #edit-title-wrapper {
        label {
          font-size: 2rem;
          color: $wcm-red;
          padding: 2rem 0 0.5rem 0;
        }
      }
      .form-item {
        span.select2 {
          width: 45rem !important;
        }
      }
    }
    .view-content {
      .views-field-field-housing-fee-description {
        .housing-fee-title {
          margin-top: 5rem;
        }
        .housing-fee-description {
          padding: 3rem 0;
        }
        .housing-fee-link {
          float: left;
        }
      }
    }
  }
  // housing options details end
  .pane-node-field-current-resident-info {
    padding-top: 2rem;
  }
  // incoming resident housing options begin
  .pane-incoming-residents-panel-pane-1 {
    .views-row {
      background-color: #f5f5f5;
      margin-bottom: 3rem;
      .views-field-field-housing-banner-image {
        position: relative;
        cursor: pointer;
        img {
          width: 100%;
        }
        &:hover {
          .housing-image-overlay {
            p.housing-image-overlay-text {
              .glyphicon-triangle-bottom {
                @include custom-animation(vertical 2s 1);
              }
            }
          }
        }
        .housing-image-overlay {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          background-color: rgba(0,0,0,.3);
          @include custom-justify-center();
          p.housing-image-overlay-text {
            margin: 0;
            font-family: '1898Sans-Regular';
            font-size: 3rem;
            color: #fff;
            font-weight: 600;
            @include breakpoint($sm) {
              font-size: 4.2rem;
            }
            .glyphicon {
              position: absolute;
              top: 65%;
              left: 48%;
              display: block;
              font-size: 2rem;
              @include custom-tranisition(top 0.5s ease-in-out);
              &.glyphicon-triangle-bottom {
                &:before {
                  color: #fff;
                  font-size: 2rem;
                }
              }
              &.glyphicon-triangle-top {
                top: 86.5%;
                @include breakpoint($sm) {
                  top: 92.5%;
                }
                &:before {
                  color: #f5f5f5;
                  font-size: 3rem;
                }
              }
            }
          }
        }
      }
      .views-field-nothing {
        .property-body {
          padding: 2rem 3rem;
          .row {
            div.col-md-6 {
              color: #555;
              &:first-child {
                position: relative;
                &:before {
                  content: '';
                  position: absolute;
                  top: 5%;
                  right: 0%;
                  height: 95%;
                  @include breakpoint($md) {
                    border-right: 3px solid #fff;
                  }
                }
                .item-list {
                  ul {
                    padding: 0 0 0 3rem;
                    li {
                      position: relative;
                      padding: 0 1rem 1.3rem 0.5rem;
                      @include breakpoint($md) {
                        padding: 0 1rem 2rem 0.5rem;
                      }
                    }
                  }
                }
              }
              &:last-child {
                .housing-cost-details {
                  position: relative;
                  margin: 2rem 0 0 3rem;
                  padding: 0 0 0 1rem;
                }
                .housing-resident-type {
                  position: relative;
                  margin: 3rem 0 0 3rem;
                  padding: 0 0 0 1rem;
                }
              }
            }
            .housing-title {
              font-size: 2.1rem;
              font-weight: 600;
            }
          }
        }
        a {
          font-size: 1.3rem;
          color: #fff;
          .view-this-property {
            padding: 1.5rem 0;
            text-align: center;
            background-color: #cf4520;
            .glyphicon {
              font-size: 1rem;
              padding-left: 0.2rem;
              position: relative;
              &:before {
                position: absolute;
                bottom: 0;
                left: 0;
              }
            }
          }
          &:hover {
            text-decoration: none;
            .view-this-property {
              background-color: $wcm-bright-orange;
              .glyphicon {
                left: 0.5rem;
              }
            }
          }
        }
      }
      @include custom-ul-list-style;
    }
  }
  .pane-faq-panel-pane-4 {
    .view-filters {
      padding-bottom: 2rem;
      form {
        .select2 {
          width: 30rem !important;
        }
      }
    }
    .view-content {
      .housing-faq-accordion {
        .answer {
          clear: both;
          margin-top: 2rem;
          .faq-answer-title {
            float: left;
            + p {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
  // news page view begin
  .pane-news-panel-pane-7 {
    .view-filters {
      form {
        .select2 {
          width: 30rem !important;
        }
      }
    }
    .view-content {
      padding-top: 3rem;
      .views-row {
        border-bottom: 1px solid #eee;
        margin-bottom: 2rem;
        padding-bottom: 2rem;
        h3 {
          margin: 0;
        }
        .teaser-image {
          img {
            margin-right: 2rem;
            width: 100%;
            max-width: 100%;
            float: none;
            @include breakpoint($sm) {
              width: 25%;
              max-width: 25%;
              float: left;
            }
          }
        }
        .custom-news-category {
          a {
            color: #cf4520;
            font-size: 14px;
            background: url(../images/tag.png) 0 0 no-repeat;
            background-size: 16px 16px;
            padding-left: 25px;
            position: relative;
          }
        }
        .custom-created-date {
          font-family: "1898Sans-Bold";
          font-size: 1.2rem;
          letter-spacing: 0.8px;
          text-transform: uppercase;
          color: #626262;
          margin: 1.2rem 0;
        }
      }
    }
    .view-footer {
      float: left;
      padding-top: 1rem;
    }
  }
  // news page view end
  .node-webform {
    h2 {
      display: none;
    }
  }
  // staff directory
  .pane-profiles-panel-pane-3 {
    padding-top: 2rem;
    .views-row {
      padding-bottom: 1.5rem;
      margin-bottom: 1.5rem;
      clear: both;
      min-height: 7rem;
      &:not(:last-child) {
        border-bottom: 1px solid #ddd;
      }
      .views-field {
        padding-bottom: 1rem;
        .views-label {
          font-weight: 600;
        }
      }
      .views-field-field-profile-image {
        @include breakpoint($sm) {
          float: left;
          margin-right: 2rem;
          width: 20%;
        }
        img {
          width: auto;
          max-height: 18rem;
        }
      }
      .views-field-nothing {
        @include breakpoint($sm) {
          float: left;
          width: 70%;
          //padding-top: 6rem;
          padding-top: 0;
        }
      }
    }
  }
  .housing-second-nav-container {
    .glyphicon {
      &:before {
        font-size: 1.5rem;
      }
    }
  }
  .pane-faq-panel-pane-3 {
    .pane-title {
      padding-bottom: 2rem;
    }
  }
}
