// Bring in a few things from Bootstrap so we can use the grid, clearfix and vendor mixins (for transition, etc)
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/variables";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_clearfix";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_grid";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_vendor-prefixes";

// Bring in the vars and custom mixins defined in the WCMC Bootstrap theme. Sets the WCMC Colors and Breakpoints
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_variables";
@import "../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_mixins";

@import "settings";
@import "mixins";
@import "homepage";
@import "fullhero_3col";
@import "hero_3col";
@import "news_2col";
@import "housing_living";
@import "nojs";

/**
 * Navigation
 */
// body.page-home {
//   #top-nav {
//     position: relative;
//     background-color: #fff;
//     width: 135.25rem;
//     margin: 0 auto;
//     left: 0;
//     right: 0;
//     &:before {
//       position: absolute;
//       content: '';
//       background-color: $wcm-dark-orange;
//       top: 44px;
//       width: inherit;
//       height: 5px;
//       margin: 0 auto;
//       left: 0;
//       right: 0;
//     }
//   }
//   #primary-nav {
//     ul {
//       border-bottom: none !important;
//     }
//   }
//   #drawer-nav {
//     ul {
//       padding-top: 5px;
//     }
//   }
// }
#primary-nav {
  ul {
    li.level-1 {
      white-space: nowrap;
    /*  width: auto;*/
      padding: 1rem 0rem;
      width: 34%;
      font-size: 12.5px;
      @include breakpoint($md) {
        font-size: 15px;
      }

      @include breakpoint($lg) {
        font-size: 16px;
      }
      .active-trail {
        color: #B31B1B;
      }
    }
    li:nth-child(2),
    li:nth-child(3) {
      width: 21%;
    }
    li:nth-child(4),
    li:nth-child(5) {
      width: 12%;
    }
  }
}


.information-column {
  .pane-wcmc-contact-pane {
    padding-top: 3rem;
  }
  .pane-wcm-cta {
    .wcm-cta {
      .wcm-cta__detail {
        .cta-icon-pay,
        .cta-icon-feedback {
          + span {
            line-height: 1.2;
          }
        }
        .cta-icon-email,
        .cta-icon-help {
          + span {
            line-height: 2.2;
          }
        }
      }
    }
  }
  .fieldable-panels-pane {
    .maintenance-request-container {
      padding-top: 1rem;
    }
  }
}

footer {
  padding-top: 0;
  .footer-contact {
    .footer-contact__site-name a {
      font-size: 15px;
      @include breakpoint($md) {
        font-size: 18px;
      }
    }
  }
  .footer-site {
    .footer-address {
      border-right: none;
      @include breakpoint($sm) {
        width: 24% !important;
      }
      @include breakpoint($md) {
        width: 20% !important;
      }
    }
    .footer-nav {
      margin-bottom: 2rem;
      border-left: 1px solid #DDD;
      @include breakpoint($sm) {
        width: 75% !important;
      }
      @include breakpoint($md) {
        width: 79% !important;
      }
      .block {
        @include custom-multi-column;
        @include custom-multi-gap;
        ul.menu {
          width: 100%;
          @include breakpoint($md) {
            width: 85%;
          }
          li {
            width: 95%;
          }
          > li:nth-child(1),
          > li:nth-child(2),
          > li:nth-child(4) {
            display: inline-block;
          }
        }
      }
    }
  }
}

/**
 * global classes
 */
#navbar-bar,
#navbar-tray {
  z-index: 2002;
}
.slideup, .slidedown {
  max-height: 0;
  overflow: hidden;
  @include custom-tranisition(all 0.5s ease-in-out);

}
.slidedown {
  max-height: 150rem;
}

.layover-fadein {
  max-height: 25.5rem;
  z-index: 2001;
  overflow: hidden;
  @include custom-tranisition(max-height 0.5s ease-in-out);
}
.layover-fadeout {
  max-height: 0;
  z-index: -1;
  overflow: hidden;
  //@include custom-tranisition(max-height 0.6s ease-in-out);
}

// fieldable panels pane begin
.fieldable-panels-pane {
  .incoming-residents-3col-wrapper {
    display: block;
    margin-bottom: 0rem;
    @include breakpoint($md) {
      height: 18rem;
      @include custom-box-spacing(div, col-md-4, padding, 1rem);
    }
    @include breakpoint($lg) {
      height: 20rem;
      @include custom-box-spacing(div, col-md-4, padding, 1rem);
    }
    .incoming-residents-box {
      position: relative;
      text-align: center;
      @include breakpoint($md) {
        max-width: 34rem;
      }
      p.incoming-residents-text {
        position: absolute;
        top: 44%;
        left: 0;
        right: 0;
        color: #fff;
        font-family: '1898Sans-Regular';
        font-size: 2rem;
        z-index: 2;
        text-align: center;
        .glyphicon {
          top: 0;
          font-size: 1.2rem;
        }
      }
      p.incoming-residents-layer {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 1;
      }
      img {
        height: auto;
        @include breakpoint($md) {
          height: 15rem;
        }
        @include breakpoint($lg) {
          height: 18rem;
        }
      }
      &:hover {
        p.incoming-residents-text {
          .glyphicon {
            left: 4px;
          }
        }
      }
    }
  }
  .housing-second-nav-container {
    .row {
      padding-bottom: 1rem;
      h3 {
        min-height: 3.8rem;
        line-height: 1.4;
        .glyphicon {
          &:before {
            color: $wcm-bright-orange;
          }
        }
      }
      a {
        outline: none;
        &:hover {
          h3 {
            color: $wcm-bright-orange;
          }
        }
      }
    }
  }
}
// fieldable panels pane end

// $amenities icons
$amenities: (
  'icon-amenities-2',
  'icon-amenities-4',
  'icon-amenities-6',
  'icon-amenities-1',
  'icon-amenities-7',
  'icon-amenities-3',
  'icon-amenities-9',
  'icon-amenities-8',
  'icon-amenities-5',
  'icon-amenities-18',
  'icon-amenities-19',
  'icon-amenities-20',
  'icon-amenities-21',
  'icon-amenities-22',
  'icon-amenities-23',
  'icon-amenities-24',
  'icon-amenities-25',
  'icon-resident-type',
  'icon-cost',
  'icon-location',
  'icon-floorplan'
);
$icon_pos_x: (
  3px,
  -23px,
  -48px,
  -72px,
  -98px,
  -123px,
  -148px,
  -173px,
  -196px,
  -400px,
  -349px,
  -525px,
  -322px,
  -424px,
  -525px,
  -550px,
  -449px,
  -299px,
  -249px,
  -223px,
  -273px
);
@each $item in $amenities {
  $idx: index($amenities, $item);
  .#{$item} {
    &:before {
      content: '';
      position: absolute;
      background: transparent url(../images/sprite/housing-icons.png) no-repeat;
      background-position: append(nth($icon_pos_x, $idx), -2px, 'comma');
      background-size: 58rem;
      width: 27px;
      height: 27px;
      opacity: 1;
      left: -30px;
      top: -1px;
    }
  }
}

.glyphicon-triangle-right {
  &:before {
    content: "\e250";
  }
}
.glyphicon-triangle-left {
  &:before {
    content: '\e251';
  }
}
.glyphicon-triangle-top {
  &:before {
    content: '\e253';
  }
}
.glyphicon-triangle-bottom {
  &:before {
    content: '\e252';
  }
}

#application-process {
  position: relative;
  .wcm-cta-item {
    z-index: 1;
  }
  #application-process-form {
    position: absolute;
    top: 0;
    left: 0;
    #housing-panes-form {
      position: relative;
      background: #f7f7f6;
      padding: 2.3rem;
      border: 1px solid #eee;
      .form-button-close {
        position: absolute;
        top: 0.5rem;
        right: 1rem;
        cursor: pointer;
        font-family: 'Glyphicons Halflings';
        font-style: normal;
        font-weight: 400;
        font-size: 1.8rem;
        text-shadow: 0 1px 0 #fff;
        color: #555;
        &:hover {
          color: $wcm-bright-orange;
          text-decoration: none;
        }
        &:after {
          content: '\e014'; /* X symbol */
        }
      }
      .form-item.form-type-radios {
        > label {
          font-size: 1.7rem;
          font-weight: 400;
          padding: 0.4rem 0;
          color: #cf4520;
          @include breakpoint($lg) {
            font-size: 1.9rem;
          }
        }
        .form-radios {
          padding-right: 1rem;
          margin-bottom: 1.8rem;
          .form-radio {
            display: none;
          }
          .form-type-radio {
            display: block;
            background-image: url("../images/radios-small.png");
            width: 22px;
            height: 22px;
            cursor: pointer;
            background-position: 0px 0px;
            margin-right: 4rem;
            margin-bottom: 1.4rem;
            min-height: 22px;
            label {
              margin-left: 0.8rem;
              text-align: left;
              max-width: none;
              font-size: 1.4rem;
              width: 20rem;
              vertical-align: middle;
              display: table;
              @include breakpoint($lg) {
                font-size: 1.5rem;
              }
            }
          }
          .form-type-radio-checked {
            background-position: 0px -22px;
          }
        }
      }
    }
  }
}

.pane-front-cta-pane {
  #application-process {
    #application-process-form {
      //display: inline-block;
      width: 90%;
      left: 0;
      right: 0;
      margin: 0 auto;
      @include custom-box-shadow(3px 3px 30px 3px #aaa);
      @include custom-border-radius(10px, 10px, 10px, 10px);
      @include breakpoint($md) {
        width: 100%;
        top: 3.5%;
      }
      @include breakpoint($lg) {
        top: 6.2%;
      }
      #housing-panes-form {
        text-align: left;
        padding: 1rem;
        min-width: 22rem;
        min-height: 22rem;
        @include breakpoint($md) {
          padding: 1.5rem;
        }
        @include breakpoint($lg) {
          min-width: 25.4rem;
          min-height: 25.4rem;
          padding: 2rem;
        }
      }
    }
  }
  a {
    &:focus {
      outline: 0;
    }
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-focus-inner {
      border: 0;
    }
  }
}

.pane-wcm-cta {
  #application-process {
    .wcm-cta__tile {
      border-right: none;
    }
    #application-process-form {
      top: -1.8rem;
      left: -1.5rem;
      //@include custom-box-shadow(3px 3px 30px 3px #777);
      #housing-panes-form {
        min-width: 25rem;
        min-height: 25rem;
        font-size: 1.8rem;
        @include custom-border-radius(10px, 10px, 10px, 10px);
      }
    }
  }
  a {
    &:focus {
      outline: 0;
    }
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-focus-inner {
      border: 0;
    }
  }
}
