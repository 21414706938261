/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
@-webkit-keyframes horizontal {
  0%, 12%, 24%, 36%, 100% {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0); }
  6%, 18%, 30% {
    -webkit-transform: translate(5px, 0);
    -moz-transform: translate(5px, 0);
    -ms-transform: translate(5px, 0);
    -o-transform: translate(5px, 0);
    transform: translate(5px, 0); } }

@-moz-keyframes horizontal {
  0%, 12%, 24%, 36%, 100% {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0); }
  6%, 18%, 30% {
    -webkit-transform: translate(5px, 0);
    -moz-transform: translate(5px, 0);
    -ms-transform: translate(5px, 0);
    -o-transform: translate(5px, 0);
    transform: translate(5px, 0); } }

@-ms-keyframes horizontal {
  0%, 12%, 24%, 36%, 100% {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0); }
  6%, 18%, 30% {
    -webkit-transform: translate(5px, 0);
    -moz-transform: translate(5px, 0);
    -ms-transform: translate(5px, 0);
    -o-transform: translate(5px, 0);
    transform: translate(5px, 0); } }

@-o-keyframes horizontal {
  0%, 12%, 24%, 36%, 100% {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0); }
  6%, 18%, 30% {
    -webkit-transform: translate(5px, 0);
    -moz-transform: translate(5px, 0);
    -ms-transform: translate(5px, 0);
    -o-transform: translate(5px, 0);
    transform: translate(5px, 0); } }

@keyframes horizontal {
  0%, 12%, 24%, 36%, 100% {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0); }
  6%, 18%, 30% {
    -webkit-transform: translate(5px, 0);
    -moz-transform: translate(5px, 0);
    -ms-transform: translate(5px, 0);
    -o-transform: translate(5px, 0);
    transform: translate(5px, 0); } }

@-webkit-keyframes vertical {
  0%, 12%, 24%, 36%, 100% {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0); }
  6%, 18%, 30% {
    -webkit-transform: translate(0, 5px);
    -moz-transform: translate(0, 5px);
    -ms-transform: translate(0, 5px);
    -o-transform: translate(0, 5px);
    transform: translate(0, 5px); } }

@-moz-keyframes vertical {
  0%, 12%, 24%, 36%, 100% {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0); }
  6%, 18%, 30% {
    -webkit-transform: translate(0, 5px);
    -moz-transform: translate(0, 5px);
    -ms-transform: translate(0, 5px);
    -o-transform: translate(0, 5px);
    transform: translate(0, 5px); } }

@-ms-keyframes vertical {
  0%, 12%, 24%, 36%, 100% {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0); }
  6%, 18%, 30% {
    -webkit-transform: translate(0, 5px);
    -moz-transform: translate(0, 5px);
    -ms-transform: translate(0, 5px);
    -o-transform: translate(0, 5px);
    transform: translate(0, 5px); } }

@-o-keyframes vertical {
  0%, 12%, 24%, 36%, 100% {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0); }
  6%, 18%, 30% {
    -webkit-transform: translate(0, 5px);
    -moz-transform: translate(0, 5px);
    -ms-transform: translate(0, 5px);
    -o-transform: translate(0, 5px);
    transform: translate(0, 5px); } }

@keyframes vertical {
  0%, 12%, 24%, 36%, 100% {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0); }
  6%, 18%, 30% {
    -webkit-transform: translate(0, 5px);
    -moz-transform: translate(0, 5px);
    -ms-transform: translate(0, 5px);
    -o-transform: translate(0, 5px);
    transform: translate(0, 5px); } }

/**
 * Homepage layout
 */
.panel-housing-home .container-fluid {
  padding: 0; }
  .panel-housing-home .container-fluid .row {
    margin: 0; }

.panel-housing-home .pane-front-cta-pane {
  padding-top: 1rem; }
  @media screen and (min-width: 992px) {
    .panel-housing-home .pane-front-cta-pane {
      padding-top: 0; } }
  .panel-housing-home .pane-front-cta-pane .front-cta-pane {
    background: #eee; }
    .panel-housing-home .pane-front-cta-pane .front-cta-pane .row {
      max-width: 100%;
      margin: 0 auto; }
      @media screen and (min-width: 992px) {
        .panel-housing-home .pane-front-cta-pane .front-cta-pane .row {
          max-width: 90%; } }
      .panel-housing-home .pane-front-cta-pane .front-cta-pane .row .wcm-cta-wrapper {
        display: inline-block;
        text-align: center;
        padding: 1.5rem; }
        @media screen and (min-width: 992px) {
          .panel-housing-home .pane-front-cta-pane .front-cta-pane .row .wcm-cta-wrapper {
            padding: 0; } }
        .panel-housing-home .pane-front-cta-pane .front-cta-pane .row .wcm-cta-wrapper a.wcm-cta-item {
          text-align: center;
          display: inline-block;
          padding: 3rem 2rem 1rem 2rem;
          width: 88%;
          height: 18rem;
          -webkit-border-top-left-radius: 10px;
          -webkit-border-top-right-radius: 10px;
          -webkit-border-bottom-right-radius: 10px;
          -webkit-border-bottom-left-radius: 10px;
          -moz-border-radius-topleft: 10px;
          -moz-border-radius-topright: 10px;
          -moz-border-radius-bottomright: 10px;
          -moz-border-radius-bottomleft: 10px;
          -ms-border-top-left-radius: 10px;
          -ms-border-top-right-radius: 10px;
          -ms-border-bottom-right-radius: 10px;
          -ms-border-bottom-left-radius: 10px;
          -o-border-top-left-radius: 10px;
          -o-border-top-right-radius: 10px;
          -o-border-bottom-right-radius: 10px;
          -o-border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px; }
          @media screen and (min-width: 992px) {
            .panel-housing-home .pane-front-cta-pane .front-cta-pane .row .wcm-cta-wrapper a.wcm-cta-item {
              padding: 5rem 2rem 3rem 2rem;
              margin: 1rem;
              width: 23rem;
              height: 23rem; } }
          @media screen and (min-width: 1200px) {
            .panel-housing-home .pane-front-cta-pane .front-cta-pane .row .wcm-cta-wrapper a.wcm-cta-item {
              padding: 6rem 0;
              margin: 2rem 0;
              width: 25rem;
              height: 25rem; } }
          .panel-housing-home .pane-front-cta-pane .front-cta-pane .row .wcm-cta-wrapper a.wcm-cta-item:hover {
            background: #fff;
            text-decoration: none;
            -webkit-box-shadow: 3px 3px 30px 3px #929292;
            -moz-box-shadow: 3px 3px 30px 3px #929292;
            -ms-box-shadow: 3px 3px 30px 3px #929292;
            -o-box-shadow: 3px 3px 30px 3px #929292;
            box-shadow: 3px 3px 30px 3px #929292;
            -webkit-transition: box-shadow 0.3s ease-in;
            -moz-transition: box-shadow 0.3s ease-in;
            -ms-transition: box-shadow 0.3s ease-in;
            -o-transition: box-shadow 0.3s ease-in;
            transition: box-shadow 0.3s ease-in; }
          .panel-housing-home .pane-front-cta-pane .front-cta-pane .row .wcm-cta-wrapper a.wcm-cta-item .cta-icon {
            width: 6.4rem;
            height: 6.4rem;
            color: #b31b1b;
            fill: #e97623;
            margin-bottom: 2rem; }
          .panel-housing-home .pane-front-cta-pane .front-cta-pane .row .wcm-cta-wrapper a.wcm-cta-item .wcm-cta-callout-title {
            display: block;
            font-size: 1.9rem;
            white-space: nowrap; }

.panel-housing-home #housing-home-content-footer .panel-pane p {
  padding: 0;
  margin: 0; }

.panel-housing-home #housing-home-content-footer .panel-pane .living-in-nyc-container {
  position: relative;
  margin-top: 1.2rem;
  display: block; }
  .panel-housing-home #housing-home-content-footer .panel-pane .living-in-nyc-container:hover .living-in-nyc-title {
    color: #e7751d; }
    .panel-housing-home #housing-home-content-footer .panel-pane .living-in-nyc-container:hover .living-in-nyc-title .glyphicon-triangle-right {
      -webkit-animation: horizontal 2s 1;
      -moz-animation: horizontal 2s 1;
      -ms-animation: horizontal 2s 1;
      -o-animation: horizontal 2s 1;
      animation: horizontal 2s 1; }
  .panel-housing-home #housing-home-content-footer .panel-pane .living-in-nyc-container .living-in-nyc-title {
    position: absolute;
    font-family: '1898Sans-Regular';
    font-weight: 800;
    color: #efefef;
    z-index: 2;
    top: 47%;
    left: 15%;
    font-size: 4rem; }
    @media screen and (min-width: 768px) {
      .panel-housing-home #housing-home-content-footer .panel-pane .living-in-nyc-container .living-in-nyc-title {
        left: 38%; } }
    .panel-housing-home #housing-home-content-footer .panel-pane .living-in-nyc-container .living-in-nyc-title .glyphicon-triangle-right {
      left: -5px; }
      .panel-housing-home #housing-home-content-footer .panel-pane .living-in-nyc-container .living-in-nyc-title .glyphicon-triangle-right:before {
        font-size: 2.7rem; }
  .panel-housing-home #housing-home-content-footer .panel-pane .living-in-nyc-container .living-in-nyc-img {
    width: 100%;
    height: 55rem;
    background-image: url("/sites/default/files/styles/panopoly_image_original/public/living-in-nyc.jpg");
    background-repeat: no-repeat;
    background-position: 50%, 0%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    z-index: 0; }
    .panel-housing-home #housing-home-content-footer .panel-pane .living-in-nyc-container .living-in-nyc-img:before {
      content: ' ';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0.8;
      background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.2)), color-stop(100%, rgba(0, 0, 0, 0.2)));
      /* Chrome,Safari4+ */
      background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);
      /* Chrome10+,Safari5.1+ */
      background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);
      background-image: -ms-linear-gradient(top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);
      background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);
      /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', GradientType='0');
      /* IE6-9 */
      -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', gradientType='0')";
      z-index: 1;
      will-change: transform; }

#housing-home-content-header {
  margin-top: -1px; }

#housing-home-content-top {
  display: block;
  text-align: center;
  max-width: 160rem;
  width: 100%;
  margin: 0 auto; }
  #housing-home-content-top .container-fluid {
    height: 100%; }
    @media screen and (min-width: 992px) {
      #housing-home-content-top .container-fluid {
        height: 64rem; } }
    #housing-home-content-top .container-fluid div.container-block {
      width: 100%;
      display: block;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      text-align: center;
      -webkit-transition: width 0.5s ease;
      -moz-transition: width 0.5s ease;
      -ms-transition: width 0.5s ease;
      -o-transition: width 0.5s ease;
      transition: width 0.5s ease; }
      @media screen and (min-width: 992px) {
        #housing-home-content-top .container-fluid div.container-block {
          display: inline-block;
          width: 32.9%; }
          #housing-home-content-top .container-fluid div.container-block:not(:last-child) {
            border-right: 9px solid #fff; } }
      @media screen and (min-width: 992px) {
        #housing-home-content-top .container-fluid div.container-block:hover {
          width: 40%; } }
      #housing-home-content-top .container-fluid div.container-block .panel-pane .fieldable-panels-pane {
        position: relative; }
        #housing-home-content-top .container-fluid div.container-block .panel-pane .fieldable-panels-pane .container-wrapper {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
          z-index: 0; }
          @media screen and (min-width: 992px) {
            #housing-home-content-top .container-fluid div.container-block .panel-pane .fieldable-panels-pane .container-wrapper {
              -webkit-transition: top 0.5s, bottom 0.5s;
              -moz-transition: top 0.5s, bottom 0.5s;
              -ms-transition: top 0.5s, bottom 0.5s;
              -o-transition: top 0.5s, bottom 0.5s;
              transition: top 0.5s, bottom 0.5s; } }
        #housing-home-content-top .container-fluid div.container-block .panel-pane .fieldable-panels-pane .container-layer {
          position: absolute;
          top: 100%;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: #fff;
          opacity: .9;
          width: 100%;
          height: 100%;
          z-index: 2; }
          @media screen and (min-width: 992px) {
            #housing-home-content-top .container-fluid div.container-block .panel-pane .fieldable-panels-pane .container-layer {
              -webkit-transition: top 0.5s, bottom 0.5s;
              -moz-transition: top 0.5s, bottom 0.5s;
              -ms-transition: top 0.5s, bottom 0.5s;
              -o-transition: top 0.5s, bottom 0.5s;
              transition: top 0.5s, bottom 0.5s; } }
        #housing-home-content-top .container-fluid div.container-block .panel-pane .fieldable-panels-pane .container-title {
          position: absolute;
          top: 30%;
          left: 16%;
          color: #fff;
          width: 22rem;
          font-family: '1898Sans-Regular';
          font-size: 2.8rem;
          font-weight: 600;
          line-height: 1.2;
          text-align: left;
          max-width: 32rem;
          z-index: 2;
          opacity: 1; }
          @media screen and (min-width: 768px) {
            #housing-home-content-top .container-fluid div.container-block .panel-pane .fieldable-panels-pane .container-title {
              font-size: 3.4rem;
              top: 35%;
              left: 25%; } }
          @media screen and (min-width: 992px) {
            #housing-home-content-top .container-fluid div.container-block .panel-pane .fieldable-panels-pane .container-title {
              top: 70%;
              left: 12%;
              -webkit-transition: all 0.5s ease;
              -moz-transition: all 0.5s ease;
              -ms-transition: all 0.5s ease;
              -o-transition: all 0.5s ease;
              transition: all 0.5s ease; } }
          #housing-home-content-top .container-fluid div.container-block .panel-pane .fieldable-panels-pane .container-title .container-description {
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 1.3;
            padding-top: 1rem;
            width: 25rem; }
            @media screen and (min-width: 768px) {
              #housing-home-content-top .container-fluid div.container-block .panel-pane .fieldable-panels-pane .container-title .container-description {
                padding-top: 2rem;
                line-height: 1.4;
                width: 28rem; } }
            @media screen and (min-width: 992px) {
              #housing-home-content-top .container-fluid div.container-block .panel-pane .fieldable-panels-pane .container-title .container-description {
                opacity: 0;
                font-size: 1.8rem;
                padding-top: 2.6rem; } }
          #housing-home-content-top .container-fluid div.container-block .panel-pane .fieldable-panels-pane .container-title .glyphicon {
            font-size: 1.4rem;
            color: #e7751d; }
        @media screen and (min-width: 992px) {
          #housing-home-content-top .container-fluid div.container-block .panel-pane .fieldable-panels-pane:hover .container-wrapper {
            bottom: 100%;
            -webkit-transition: top 0.5s, bottom 0.5s;
            -moz-transition: top 0.5s, bottom 0.5s;
            -ms-transition: top 0.5s, bottom 0.5s;
            -o-transition: top 0.5s, bottom 0.5s;
            transition: top 0.5s, bottom 0.5s; }
          #housing-home-content-top .container-fluid div.container-block .panel-pane .fieldable-panels-pane:hover .container-layer {
            top: 0;
            -webkit-transition: top 0.5s, bottom 0.5s;
            -moz-transition: top 0.5s, bottom 0.5s;
            -ms-transition: top 0.5s, bottom 0.5s;
            -o-transition: top 0.5s, bottom 0.5s;
            transition: top 0.5s, bottom 0.5s; }
          #housing-home-content-top .container-fluid div.container-block .panel-pane .fieldable-panels-pane:hover .container-title {
            position: absolute;
            top: 35%;
            left: 18%;
            color: #cf4520;
            -webkit-transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            -ms-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
            transition: all 0.5s ease; }
            #housing-home-content-top .container-fluid div.container-block .panel-pane .fieldable-panels-pane:hover .container-title .container-description {
              opacity: 1;
              -webkit-transition: opacity 0.5s ease;
              -moz-transition: opacity 0.5s ease;
              -ms-transition: opacity 0.5s ease;
              -o-transition: opacity 0.5s ease;
              transition: opacity 0.5s ease; } }
        #housing-home-content-top .container-fluid div.container-block .panel-pane .fieldable-panels-pane img {
          max-width: 100%;
          height: auto;
          margin: 0;
          z-index: 0; }
          @media screen and (min-width: 992px) {
            #housing-home-content-top .container-fluid div.container-block .panel-pane .fieldable-panels-pane img {
              max-width: none;
              max-height: 63rem;
              margin: 0 -100%; } }
      #housing-home-content-top .container-fluid div.container-block p {
        margin: 0; }

#housing-home-content-footer .panel-pane .fieldable-panels-pane {
  margin-bottom: -1px; }

/**
 * Full Hero 3 Column layout
 */
.panel-fullhero-3col .main-content {
  padding-bottom: 4rem; }

.panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row-first {
  border-top: 1px solid #eee; }

.panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row {
  position: relative; }
  @media screen and (min-width: 992px) {
    .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row {
      border-right: 1.5rem solid #f7f7f7;
      border-top: none; }
      .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row:before {
        content: '';
        display: block;
        position: absolute;
        right: -1.8rem;
        top: 45%;
        width: 0;
        height: 0;
        border-top: 1rem solid transparent;
        border-right: 1rem solid transparent;
        border-bottom: 1rem solid transparent;
        border-left: 1rem solid #fff; }
      .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row:after {
        content: '';
        display: block;
        position: absolute;
        right: -3.4rem;
        top: 45%;
        width: 0;
        height: 0;
        border-top: 1rem solid transparent;
        border-right: 1rem solid transparent;
        border-bottom: 1rem solid transparent;
        border-left: 1rem solid #f7f7f7; } }
  @media screen and (min-width: 992px) {
    .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row:hover {
      background-color: #f7f7f7;
      border-right-color: #cf4520;
      -webkit-transition: all 0.3s ease-in;
      -moz-transition: all 0.3s ease-in;
      -ms-transition: all 0.3s ease-in;
      -o-transition: all 0.3s ease-in;
      transition: all 0.3s ease-in; }
      .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row:hover:before {
        border-left: 1rem solid #f7f7f7; }
      .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row:hover:after {
        border-left: 1rem solid #cf4520; } }
  .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row:hover .views-field-nothing .row .housing-image .housing-hero-image-overlay {
    opacity: 0.6;
    -webkit-transition: opacity 0.3s ease-in;
    -moz-transition: opacity 0.3s ease-in;
    -ms-transition: opacity 0.3s ease-in;
    -o-transition: opacity 0.3s ease-in;
    transition: opacity 0.3s ease-in; }
  .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row:hover .views-field-nothing .row .housing-image .btn-view-this-property {
    opacity: 1;
    -webkit-transition: opacity 0.3s ease-in;
    -moz-transition: opacity 0.3s ease-in;
    -ms-transition: opacity 0.3s ease-in;
    -o-transition: opacity 0.3s ease-in;
    transition: opacity 0.3s ease-in; }
  .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row div.views-field-nothing {
    padding-bottom: 2rem;
    border-bottom: 1px solid #efefef; }
    .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row div.views-field-nothing a {
      color: #555555;
      outline: none; }
      .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row div.views-field-nothing a:hover {
        text-decoration: none; }
      .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row div.views-field-nothing a .views-field-title {
        padding: 3rem 0 2rem 2rem; }
        @media screen and (min-width: 992px) {
          .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row div.views-field-nothing a .views-field-title {
            padding: 2.5rem 0 0 2rem; } }
        .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row div.views-field-nothing a .views-field-title h3 {
          margin: 0; }
    .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row div.views-field-nothing .row {
      padding: 0; }
      @media screen and (min-width: 992px) {
        .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row div.views-field-nothing .row {
          padding: 2rem 4rem 0rem 4rem; } }
      .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row div.views-field-nothing .row .housing-image {
        position: relative;
        padding: 0; }
        .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row div.views-field-nothing .row .housing-image .housing-hero-image-overlay {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #000;
          opacity: 0; }
        .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row div.views-field-nothing .row .housing-image .btn-view-this-property {
          position: absolute;
          margin: 0 auto;
          top: 45%;
          left: 30%;
          right: 30%;
          font-size: 1.2rem;
          padding: 3px 15px;
          opacity: 0; }
          @media screen and (min-width: 992px) {
            .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row div.views-field-nothing .row .housing-image .btn-view-this-property {
              left: 25%;
              right: 25%;
              top: 43%; } }
          @media screen and (min-width: 1200px) {
            .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row div.views-field-nothing .row .housing-image .btn-view-this-property {
              font-size: 1.3rem; } }
        .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row div.views-field-nothing .row .housing-image + .col-md-4 {
          padding-right: 4rem; }
        .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row div.views-field-nothing .row .housing-image img {
          width: 100%; }
      .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row div.views-field-nothing .row .housing-location,
      .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row div.views-field-nothing .row .housing-resident-type,
      .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row div.views-field-nothing .row .housing-cost {
        position: relative; }
      .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row div.views-field-nothing .row .housing-resident-type,
      .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row div.views-field-nothing .row .housing-cost {
        border-top: 1px solid #efefef;
        padding-top: 2rem;
        margin-top: 2rem; }
      .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row div.views-field-nothing .row .housing-location {
        padding-top: 2rem; }
        @media screen and (min-width: 992px) {
          .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row div.views-field-nothing .row .housing-location {
            padding-top: 0; } }
      .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row div.views-field-nothing .row .housing-cost {
        padding-bottom: 2rem; }
        @media screen and (min-width: 992px) {
          .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row div.views-field-nothing .row .housing-cost {
            padding-bottom: 0; } }
      .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row div.views-field-nothing .row .housing-amenities ul {
        padding: 0.5rem 3rem; }
        @media screen and (min-width: 992px) {
          .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row div.views-field-nothing .row .housing-amenities ul {
            padding: 0; } }
        .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row div.views-field-nothing .row .housing-amenities ul li {
          position: relative;
          padding: 0 1rem 2rem 0.5rem; }
  .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row ul {
    list-style: none;
    padding: 0; }
    .panel-fullhero-3col .pane-housing-options-panel-pane-1 .views-row ul li {
      margin: 0; }

.panel-fullhero-3col .pane-news-panel-pane-5,
.panel-fullhero-3col .pane-news-panel-pane-6 {
  margin-top: 6.5rem;
  clear: both; }
  .panel-fullhero-3col .pane-news-panel-pane-5 .pane-title,
  .panel-fullhero-3col .pane-news-panel-pane-6 .pane-title {
    margin-bottom: 0; }
  .panel-fullhero-3col .pane-news-panel-pane-5 div.views-row,
  .panel-fullhero-3col .pane-news-panel-pane-6 div.views-row {
    padding: 3rem 0;
    clear: both; }
    .panel-fullhero-3col .pane-news-panel-pane-5 div.views-row:not(:last-child),
    .panel-fullhero-3col .pane-news-panel-pane-6 div.views-row:not(:last-child) {
      border-bottom: 1px solid #ddd; }
    @media screen and (min-width: 768px) {
      .panel-fullhero-3col .pane-news-panel-pane-5 div.views-row .teaser-image,
      .panel-fullhero-3col .pane-news-panel-pane-6 div.views-row .teaser-image {
        float: left;
        width: 25%;
        margin-right: 2.4rem; } }
    .panel-fullhero-3col .pane-news-panel-pane-5 div.views-row .teaser-image img,
    .panel-fullhero-3col .pane-news-panel-pane-6 div.views-row .teaser-image img {
      height: auto;
      width: 100%;
      max-width: 100%; }
    .panel-fullhero-3col .pane-news-panel-pane-5 div.views-row .views-field-nothing .teaser-title,
    .panel-fullhero-3col .pane-news-panel-pane-6 div.views-row .views-field-nothing .teaser-title {
      padding-top: 1rem; }
      @media screen and (min-width: 768px) {
        .panel-fullhero-3col .pane-news-panel-pane-5 div.views-row .views-field-nothing .teaser-title,
        .panel-fullhero-3col .pane-news-panel-pane-6 div.views-row .views-field-nothing .teaser-title {
          padding-top: 0; } }
    .panel-fullhero-3col .pane-news-panel-pane-5 div.views-row .views-field-nothing .post-date,
    .panel-fullhero-3col .pane-news-panel-pane-6 div.views-row .views-field-nothing .post-date {
      font-family: "1898Sans-Bold";
      font-size: 1.2rem;
      letter-spacing: 0.8px;
      text-transform: uppercase;
      color: #626262;
      margin: 1.2rem 0; }
    .panel-fullhero-3col .pane-news-panel-pane-5 div.views-row h2, .panel-fullhero-3col .pane-news-panel-pane-5 div.views-row h3, .panel-fullhero-3col .pane-news-panel-pane-5 div.views-row h4, .panel-fullhero-3col .pane-news-panel-pane-5 div.views-row h5,
    .panel-fullhero-3col .pane-news-panel-pane-6 div.views-row h2,
    .panel-fullhero-3col .pane-news-panel-pane-6 div.views-row h3,
    .panel-fullhero-3col .pane-news-panel-pane-6 div.views-row h4,
    .panel-fullhero-3col .pane-news-panel-pane-6 div.views-row h5 {
      margin-top: 0; }
  .panel-fullhero-3col .pane-news-panel-pane-5 .views-row-first,
  .panel-fullhero-3col .pane-news-panel-pane-6 .views-row-first {
    padding-top: 4rem; }
  .panel-fullhero-3col .pane-news-panel-pane-5 .view-footer,
  .panel-fullhero-3col .pane-news-panel-pane-6 .view-footer {
    padding-top: 1rem; }
    @media screen and (min-width: 992px) {
      .panel-fullhero-3col .pane-news-panel-pane-5 .view-footer,
      .panel-fullhero-3col .pane-news-panel-pane-6 .view-footer {
        float: left; } }

.panel-fullhero-3col .housing-callout-container {
  position: relative; }
  .panel-fullhero-3col .housing-callout-container .housing-callout-link .housing-callout-image {
    position: relative; }
    .panel-fullhero-3col .housing-callout-container .housing-callout-link .housing-callout-image:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 94%;
      height: 100%;
      background: #000;
      opacity: .2;
      background-size: 100% 100%; }
    .panel-fullhero-3col .housing-callout-container .housing-callout-link .housing-callout-image img {
      width: 94%; }
  .panel-fullhero-3col .housing-callout-container .housing-callout-link .housing-callout-layover {
    position: absolute;
    font-family: '1898Sans-Regular';
    font-size: 3rem;
    color: #fff;
    font-weight: 600;
    top: 42%;
    left: 0;
    right: 0;
    text-align: center; }
    @media screen and (min-width: 768px) {
      .panel-fullhero-3col .housing-callout-container .housing-callout-link .housing-callout-layover {
        font-size: 4rem; } }
    .panel-fullhero-3col .housing-callout-container .housing-callout-link .housing-callout-layover .glyphicon-triangle-right {
      font-size: 2rem; }
      @media screen and (min-width: 768px) {
        .panel-fullhero-3col .housing-callout-container .housing-callout-link .housing-callout-layover .glyphicon-triangle-right {
          font-size: 3rem; } }
  .panel-fullhero-3col .housing-callout-container .housing-callout-link:hover .housing-callout-layover {
    color: #e7751d; }
    .panel-fullhero-3col .housing-callout-container .housing-callout-link:hover .housing-callout-layover .glyphicon-triangle-right {
      -webkit-animation: horizontal 2s 1;
      -moz-animation: horizontal 2s 1;
      -ms-animation: horizontal 2s 1;
      -o-animation: horizontal 2s 1;
      animation: horizontal 2s 1; }

.panel-fullhero-3col .housing-second-nav-container .glyphicon:before {
  font-size: 1.5rem; }

/**
 * Hero 3 Column layout
 */
.panel-hero-3col .hero-section .pane-node-field-incoming-residents-image,
.panel-hero-3col .hero-section .pane-node-field-current-residents-image {
  padding-bottom: 2rem; }

.panel-hero-3col .main-content {
  padding-bottom: 4rem; }

.panel-hero-3col .pane-housing-options-panel-pane-2 .views-field-nothing .housing-details .row {
  margin: 0; }
  .panel-hero-3col .pane-housing-options-panel-pane-2 .views-field-nothing .housing-details .row div.col-md-6 {
    padding: 0; }
    @media screen and (min-width: 992px) {
      .panel-hero-3col .pane-housing-options-panel-pane-2 .views-field-nothing .housing-details .row div.col-md-6:first-child {
        border-right: 1px solid #eee; } }
  .panel-hero-3col .pane-housing-options-panel-pane-2 .views-field-nothing .housing-details .row .housing-info-1 {
    position: relative; }
    .panel-hero-3col .pane-housing-options-panel-pane-2 .views-field-nothing .housing-details .row .housing-info-1:after {
      content: '';
      background: #eee;
      position: absolute;
      bottom: 0;
      left: 0%;
      width: 95%;
      height: 1px; }
    .panel-hero-3col .pane-housing-options-panel-pane-2 .views-field-nothing .housing-details .row .housing-info-1 .housing-amenities {
      padding: 1rem 0 0 3rem;
      height: 100%;
      display: inline-block;
      clear: both; }
      .panel-hero-3col .pane-housing-options-panel-pane-2 .views-field-nothing .housing-details .row .housing-info-1 .housing-amenities ul li {
        position: relative;
        float: left;
        padding: 0 1rem 2rem 0.5rem;
        width: 18rem; }
  .panel-hero-3col .pane-housing-options-panel-pane-2 .views-field-nothing .housing-details .row .housing-info-2 {
    margin: 0 1.4rem 0 0; }
    .panel-hero-3col .pane-housing-options-panel-pane-2 .views-field-nothing .housing-details .row .housing-info-2 h3 {
      padding-bottom: 1.5rem; }
    .panel-hero-3col .pane-housing-options-panel-pane-2 .views-field-nothing .housing-details .row .housing-info-2 .housing-floorplan img.file-icon {
      display: none; }
      .panel-hero-3col .pane-housing-options-panel-pane-2 .views-field-nothing .housing-details .row .housing-info-2 .housing-floorplan img.file-icon + a {
        position: relative;
        padding-left: 3.5rem; }
        .panel-hero-3col .pane-housing-options-panel-pane-2 .views-field-nothing .housing-details .row .housing-info-2 .housing-floorplan img.file-icon + a:before {
          content: '';
          position: absolute;
          background: transparent url(../images/sprite/housing-icons.png) no-repeat;
          background-position: -273px, -2px;
          background-size: 58rem;
          width: 27px;
          height: 27px;
          opacity: 1;
          left: 2px;
          top: -1px; }
  .panel-hero-3col .pane-housing-options-panel-pane-2 .views-field-nothing .housing-details .row .housing-info-3 {
    position: relative;
    padding-bottom: 1rem;
    border-top: 1px solid #eee;
    margin-top: 2rem; }
    @media screen and (min-width: 992px) {
      .panel-hero-3col .pane-housing-options-panel-pane-2 .views-field-nothing .housing-details .row .housing-info-3 {
        border-top: none;
        margin-top: 0; } }
    .panel-hero-3col .pane-housing-options-panel-pane-2 .views-field-nothing .housing-details .row .housing-info-3:after {
      content: '';
      background: #eee;
      position: absolute;
      bottom: 0;
      left: 5%;
      width: 90%;
      height: 1px; }
    .panel-hero-3col .pane-housing-options-panel-pane-2 .views-field-nothing .housing-details .row .housing-info-3 h3 {
      padding-left: 0;
      padding-top: 0; }
      @media screen and (min-width: 992px) {
        .panel-hero-3col .pane-housing-options-panel-pane-2 .views-field-nothing .housing-details .row .housing-info-3 h3 {
          padding-left: 3rem; } }
    .panel-hero-3col .pane-housing-options-panel-pane-2 .views-field-nothing .housing-details .row .housing-info-3 .housing-cost {
      position: relative;
      padding: 0 0 0 1rem;
      margin: 2rem 0 0 3rem; }
      @media screen and (min-width: 992px) {
        .panel-hero-3col .pane-housing-options-panel-pane-2 .views-field-nothing .housing-details .row .housing-info-3 .housing-cost {
          margin: 2rem 0 0 5rem; } }
    .panel-hero-3col .pane-housing-options-panel-pane-2 .views-field-nothing .housing-details .row .housing-info-3 .housing-resident-type {
      position: relative;
      padding: 0 0 0 1rem;
      margin: 3rem 0 0 3rem; }
      @media screen and (min-width: 992px) {
        .panel-hero-3col .pane-housing-options-panel-pane-2 .views-field-nothing .housing-details .row .housing-info-3 .housing-resident-type {
          margin: 3rem 0 0 5rem; } }
  .panel-hero-3col .pane-housing-options-panel-pane-2 .views-field-nothing .housing-details .row .housing-info-4 {
    padding-left: 0; }
    @media screen and (min-width: 992px) {
      .panel-hero-3col .pane-housing-options-panel-pane-2 .views-field-nothing .housing-details .row .housing-info-4 {
        padding-left: 3rem; } }
    .panel-hero-3col .pane-housing-options-panel-pane-2 .views-field-nothing .housing-details .row .housing-info-4 .housing-location {
      position: relative;
      margin: 2rem 0 0 3rem;
      padding: 0 0 0 1rem;
      cursor: pointer; }
      @media screen and (min-width: 992px) {
        .panel-hero-3col .pane-housing-options-panel-pane-2 .views-field-nothing .housing-details .row .housing-info-4 .housing-location {
          margin: 2rem 0 0 2rem; } }
      .panel-hero-3col .pane-housing-options-panel-pane-2 .views-field-nothing .housing-details .row .housing-info-4 .housing-location:hover {
        color: #e7751d; }
      .panel-hero-3col .pane-housing-options-panel-pane-2 .views-field-nothing .housing-details .row .housing-info-4 .housing-location .icon-location:before {
        top: -1px; }

.panel-hero-3col .pane-housing-options-panel-pane-2 .views-field-field-housing-images {
  padding: 4rem 0 4rem 0; }

.panel-hero-3col .pane-housing-options-panel-pane-2 #housing-image-slideshow {
  z-index: 0;
  background-color: #f7f7f7;
  width: 100% !important;
  height: 26rem; }
  @media screen and (min-width: 992px) {
    .panel-hero-3col .pane-housing-options-panel-pane-2 #housing-image-slideshow {
      height: 36rem; } }
  @media screen and (min-width: 992px) {
    .panel-hero-3col .pane-housing-options-panel-pane-2 #housing-image-slideshow {
      height: 46.5rem; } }
  @media screen and (min-width: 1200px) {
    .panel-hero-3col .pane-housing-options-panel-pane-2 #housing-image-slideshow {
      height: 56.5rem; } }
  .panel-hero-3col .pane-housing-options-panel-pane-2 #housing-image-slideshow .housing-slideshow-item {
    text-align: center;
    width: 100% !important;
    margin: 0; }
    .panel-hero-3col .pane-housing-options-panel-pane-2 #housing-image-slideshow .housing-slideshow-item .housing-slide-image {
      margin: 0;
      padding: 0;
      background-color: #f7f7f7; }
      .panel-hero-3col .pane-housing-options-panel-pane-2 #housing-image-slideshow .housing-slideshow-item .housing-slide-image > img {
        width: auto;
        max-height: 56.5rem;
        height: 26rem; }
        @media screen and (min-width: 992px) {
          .panel-hero-3col .pane-housing-options-panel-pane-2 #housing-image-slideshow .housing-slideshow-item .housing-slide-image > img {
            height: 36rem; } }
        @media screen and (min-width: 992px) {
          .panel-hero-3col .pane-housing-options-panel-pane-2 #housing-image-slideshow .housing-slideshow-item .housing-slide-image > img {
            height: 46.5rem; } }
        @media screen and (min-width: 1200px) {
          .panel-hero-3col .pane-housing-options-panel-pane-2 #housing-image-slideshow .housing-slideshow-item .housing-slide-image > img {
            height: 56.5rem; } }
    .panel-hero-3col .pane-housing-options-panel-pane-2 #housing-image-slideshow .housing-slideshow-item .housing-slide-text {
      position: absolute;
      bottom: 0rem;
      width: 100%;
      background: #000;
      opacity: 0.65;
      color: #fff;
      height: 9rem; }
      .panel-hero-3col .pane-housing-options-panel-pane-2 #housing-image-slideshow .housing-slideshow-item .housing-slide-text .slide-caption {
        font-size: 1.3rem;
        text-align: left;
        width: inherit;
        height: inherit;
        display: table-cell;
        vertical-align: middle;
        padding: 0 2rem; }
        @media screen and (min-width: 992px) {
          .panel-hero-3col .pane-housing-options-panel-pane-2 #housing-image-slideshow .housing-slideshow-item .housing-slide-text .slide-caption {
            padding: 0 4rem; } }

.panel-hero-3col .pane-housing-options-panel-pane-2 #housing-image-control {
  position: relative;
  z-index: 1; }
  .panel-hero-3col .pane-housing-options-panel-pane-2 #housing-image-control .cycle-prev,
  .panel-hero-3col .pane-housing-options-panel-pane-2 #housing-image-control .cycle-next {
    display: block;
    width: 3.1rem;
    opacity: 0.6;
    height: 26rem; }
    @media screen and (min-width: 992px) {
      .panel-hero-3col .pane-housing-options-panel-pane-2 #housing-image-control .cycle-prev,
      .panel-hero-3col .pane-housing-options-panel-pane-2 #housing-image-control .cycle-next {
        height: 36rem; } }
    @media screen and (min-width: 992px) {
      .panel-hero-3col .pane-housing-options-panel-pane-2 #housing-image-control .cycle-prev,
      .panel-hero-3col .pane-housing-options-panel-pane-2 #housing-image-control .cycle-next {
        height: 46.5rem; } }
    @media screen and (min-width: 1200px) {
      .panel-hero-3col .pane-housing-options-panel-pane-2 #housing-image-control .cycle-prev,
      .panel-hero-3col .pane-housing-options-panel-pane-2 #housing-image-control .cycle-next {
        height: 56rem; } }
  .panel-hero-3col .pane-housing-options-panel-pane-2 #housing-image-control .cycle-prev {
    position: absolute;
    bottom: 48%;
    left: 0;
    background: url(../images/arrow-left.png) no-repeat;
    background-position: 0% 48% !important;
    background-size: 4.5rem; }
  .panel-hero-3col .pane-housing-options-panel-pane-2 #housing-image-control .cycle-next {
    position: absolute;
    bottom: 48%;
    right: 0;
    background: url(../images/arrow-right.png) no-repeat;
    background-position: 100% 48% !important;
    background-size: 4.5rem; }
  .panel-hero-3col .pane-housing-options-panel-pane-2 #housing-image-control a {
    outline: none; }

.panel-hero-3col .pane-housing-options-panel-pane-2 ul {
  list-style: none;
  padding: 0; }
  .panel-hero-3col .pane-housing-options-panel-pane-2 ul li {
    margin: 0; }

.panel-hero-3col .pane-housing-options-panel-pane-2 + .pane-bundle-map iframe {
  width: 100%; }

.panel-hero-3col .pane-housing-options-panel-pane-3 form#views-exposed-form-housing-options-panel-pane-3 #edit-title-wrapper label {
  font-size: 2rem;
  color: #b31b1b;
  padding: 2rem 0 0.5rem 0; }

.panel-hero-3col .pane-housing-options-panel-pane-3 form#views-exposed-form-housing-options-panel-pane-3 .form-item span.select2 {
  width: 45rem !important; }

.panel-hero-3col .pane-housing-options-panel-pane-3 .view-content .views-field-field-housing-fee-description .housing-fee-title {
  margin-top: 5rem; }

.panel-hero-3col .pane-housing-options-panel-pane-3 .view-content .views-field-field-housing-fee-description .housing-fee-description {
  padding: 3rem 0; }

.panel-hero-3col .pane-housing-options-panel-pane-3 .view-content .views-field-field-housing-fee-description .housing-fee-link {
  float: left; }

.panel-hero-3col .pane-node-field-current-resident-info {
  padding-top: 2rem; }

.panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row {
  background-color: #f5f5f5;
  margin-bottom: 3rem; }
  .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row .views-field-field-housing-banner-image {
    position: relative;
    cursor: pointer; }
    .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row .views-field-field-housing-banner-image img {
      width: 100%; }
    .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row .views-field-field-housing-banner-image:hover .housing-image-overlay p.housing-image-overlay-text .glyphicon-triangle-bottom {
      -webkit-animation: vertical 2s 1;
      -moz-animation: vertical 2s 1;
      -ms-animation: vertical 2s 1;
      -o-animation: vertical 2s 1;
      animation: vertical 2s 1; }
    .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row .views-field-field-housing-banner-image .housing-image-overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.3);
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center; }
      .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row .views-field-field-housing-banner-image .housing-image-overlay p.housing-image-overlay-text {
        margin: 0;
        font-family: '1898Sans-Regular';
        font-size: 3rem;
        color: #fff;
        font-weight: 600; }
        @media screen and (min-width: 768px) {
          .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row .views-field-field-housing-banner-image .housing-image-overlay p.housing-image-overlay-text {
            font-size: 4.2rem; } }
        .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row .views-field-field-housing-banner-image .housing-image-overlay p.housing-image-overlay-text .glyphicon {
          position: absolute;
          top: 65%;
          left: 48%;
          display: block;
          font-size: 2rem;
          -webkit-transition: top 0.5s ease-in-out;
          -moz-transition: top 0.5s ease-in-out;
          -ms-transition: top 0.5s ease-in-out;
          -o-transition: top 0.5s ease-in-out;
          transition: top 0.5s ease-in-out; }
          .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row .views-field-field-housing-banner-image .housing-image-overlay p.housing-image-overlay-text .glyphicon.glyphicon-triangle-bottom:before {
            color: #fff;
            font-size: 2rem; }
          .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row .views-field-field-housing-banner-image .housing-image-overlay p.housing-image-overlay-text .glyphicon.glyphicon-triangle-top {
            top: 86.5%; }
            @media screen and (min-width: 768px) {
              .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row .views-field-field-housing-banner-image .housing-image-overlay p.housing-image-overlay-text .glyphicon.glyphicon-triangle-top {
                top: 92.5%; } }
            .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row .views-field-field-housing-banner-image .housing-image-overlay p.housing-image-overlay-text .glyphicon.glyphicon-triangle-top:before {
              color: #f5f5f5;
              font-size: 3rem; }
  .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row .views-field-nothing .property-body {
    padding: 2rem 3rem; }
    .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row .views-field-nothing .property-body .row div.col-md-6 {
      color: #555; }
      .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row .views-field-nothing .property-body .row div.col-md-6:first-child {
        position: relative; }
        .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row .views-field-nothing .property-body .row div.col-md-6:first-child:before {
          content: '';
          position: absolute;
          top: 5%;
          right: 0%;
          height: 95%; }
          @media screen and (min-width: 992px) {
            .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row .views-field-nothing .property-body .row div.col-md-6:first-child:before {
              border-right: 3px solid #fff; } }
        .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row .views-field-nothing .property-body .row div.col-md-6:first-child .item-list ul {
          padding: 0 0 0 3rem; }
          .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row .views-field-nothing .property-body .row div.col-md-6:first-child .item-list ul li {
            position: relative;
            padding: 0 1rem 1.3rem 0.5rem; }
            @media screen and (min-width: 992px) {
              .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row .views-field-nothing .property-body .row div.col-md-6:first-child .item-list ul li {
                padding: 0 1rem 2rem 0.5rem; } }
      .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row .views-field-nothing .property-body .row div.col-md-6:last-child .housing-cost-details {
        position: relative;
        margin: 2rem 0 0 3rem;
        padding: 0 0 0 1rem; }
      .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row .views-field-nothing .property-body .row div.col-md-6:last-child .housing-resident-type {
        position: relative;
        margin: 3rem 0 0 3rem;
        padding: 0 0 0 1rem; }
    .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row .views-field-nothing .property-body .row .housing-title {
      font-size: 2.1rem;
      font-weight: 600; }
  .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row .views-field-nothing a {
    font-size: 1.3rem;
    color: #fff; }
    .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row .views-field-nothing a .view-this-property {
      padding: 1.5rem 0;
      text-align: center;
      background-color: #cf4520; }
      .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row .views-field-nothing a .view-this-property .glyphicon {
        font-size: 1rem;
        padding-left: 0.2rem;
        position: relative; }
        .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row .views-field-nothing a .view-this-property .glyphicon:before {
          position: absolute;
          bottom: 0;
          left: 0; }
    .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row .views-field-nothing a:hover {
      text-decoration: none; }
      .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row .views-field-nothing a:hover .view-this-property {
        background-color: #e7751d; }
        .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row .views-field-nothing a:hover .view-this-property .glyphicon {
          left: 0.5rem; }
  .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row ul {
    list-style: none;
    padding: 0; }
    .panel-hero-3col .pane-incoming-residents-panel-pane-1 .views-row ul li {
      margin: 0; }

.panel-hero-3col .pane-faq-panel-pane-4 .view-filters {
  padding-bottom: 2rem; }
  .panel-hero-3col .pane-faq-panel-pane-4 .view-filters form .select2 {
    width: 30rem !important; }

.panel-hero-3col .pane-faq-panel-pane-4 .view-content .housing-faq-accordion .answer {
  clear: both;
  margin-top: 2rem; }
  .panel-hero-3col .pane-faq-panel-pane-4 .view-content .housing-faq-accordion .answer .faq-answer-title {
    float: left; }
    .panel-hero-3col .pane-faq-panel-pane-4 .view-content .housing-faq-accordion .answer .faq-answer-title + p {
      margin-top: 0; }

.panel-hero-3col .pane-news-panel-pane-7 .view-filters form .select2 {
  width: 30rem !important; }

.panel-hero-3col .pane-news-panel-pane-7 .view-content {
  padding-top: 3rem; }
  .panel-hero-3col .pane-news-panel-pane-7 .view-content .views-row {
    border-bottom: 1px solid #eee;
    margin-bottom: 2rem;
    padding-bottom: 2rem; }
    .panel-hero-3col .pane-news-panel-pane-7 .view-content .views-row h3 {
      margin: 0; }
    .panel-hero-3col .pane-news-panel-pane-7 .view-content .views-row .teaser-image img {
      margin-right: 2rem;
      width: 100%;
      max-width: 100%;
      float: none; }
      @media screen and (min-width: 768px) {
        .panel-hero-3col .pane-news-panel-pane-7 .view-content .views-row .teaser-image img {
          width: 25%;
          max-width: 25%;
          float: left; } }
    .panel-hero-3col .pane-news-panel-pane-7 .view-content .views-row .custom-news-category a {
      color: #cf4520;
      font-size: 14px;
      background: url(../images/tag.png) 0 0 no-repeat;
      background-size: 16px 16px;
      padding-left: 25px;
      position: relative; }
    .panel-hero-3col .pane-news-panel-pane-7 .view-content .views-row .custom-created-date {
      font-family: "1898Sans-Bold";
      font-size: 1.2rem;
      letter-spacing: 0.8px;
      text-transform: uppercase;
      color: #626262;
      margin: 1.2rem 0; }

.panel-hero-3col .pane-news-panel-pane-7 .view-footer {
  float: left;
  padding-top: 1rem; }

.panel-hero-3col .node-webform h2 {
  display: none; }

.panel-hero-3col .pane-profiles-panel-pane-3 {
  padding-top: 2rem; }
  .panel-hero-3col .pane-profiles-panel-pane-3 .views-row {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    clear: both;
    min-height: 7rem; }
    .panel-hero-3col .pane-profiles-panel-pane-3 .views-row:not(:last-child) {
      border-bottom: 1px solid #ddd; }
    .panel-hero-3col .pane-profiles-panel-pane-3 .views-row .views-field {
      padding-bottom: 1rem; }
      .panel-hero-3col .pane-profiles-panel-pane-3 .views-row .views-field .views-label {
        font-weight: 600; }
    @media screen and (min-width: 768px) {
      .panel-hero-3col .pane-profiles-panel-pane-3 .views-row .views-field-field-profile-image {
        float: left;
        margin-right: 2rem;
        width: 20%; } }
    .panel-hero-3col .pane-profiles-panel-pane-3 .views-row .views-field-field-profile-image img {
      width: auto;
      max-height: 18rem; }
    @media screen and (min-width: 768px) {
      .panel-hero-3col .pane-profiles-panel-pane-3 .views-row .views-field-nothing {
        float: left;
        width: 70%;
        padding-top: 0; } }

.panel-hero-3col .housing-second-nav-container .glyphicon:before {
  font-size: 1.5rem; }

.panel-hero-3col .pane-faq-panel-pane-3 .pane-title {
  padding-bottom: 2rem; }

.panel-twocol .main-content {
  padding-bottom: 4rem; }
  .panel-twocol .main-content .news-featured-image {
    float: left;
    padding-right: 3rem; }
    .panel-twocol .main-content .news-featured-image .hero-image img {
      max-width: 45rem; }
  .panel-twocol .main-content .pane-node-field-news-category ul li a {
    color: #cf4520;
    font-size: 14px;
    background: url(../images/tag.png) 0 0 no-repeat;
    background-size: 16px 16px;
    padding-left: 25px;
    position: relative; }

.panel-housing-living #housing-content-main #housing-content-header .pane-title,
.panel-housing-living #housing-content-main #housing-content-body .pane-title,
.panel-housing-living #housing-content-main #housing-content-body-1 .pane-title,
.panel-housing-living #housing-content-main #housing-content-body-2 .pane-title,
.panel-housing-living #housing-content-main #housing-content-body-3 .pane-title,
.panel-housing-living #housing-content-main #housing-content-body-4 .pane-title {
  padding-bottom: 1rem;
  font-size: 3rem; }

.panel-housing-living #housing-content-main #housing-content-header .fieldable-panels-pane img,
.panel-housing-living #housing-content-main #housing-content-body .fieldable-panels-pane img,
.panel-housing-living #housing-content-main #housing-content-body-1 .fieldable-panels-pane img,
.panel-housing-living #housing-content-main #housing-content-body-2 .fieldable-panels-pane img,
.panel-housing-living #housing-content-main #housing-content-body-3 .fieldable-panels-pane img,
.panel-housing-living #housing-content-main #housing-content-body-4 .fieldable-panels-pane img {
  padding-bottom: 2.5rem; }

.panel-housing-living #housing-content-main #housing-content-top-right .panel-pane ul li,
.panel-housing-living #housing-content-main #housing-content-bottom-right .panel-pane ul li,
.panel-housing-living #housing-content-main #housing-content-body-1-right .panel-pane ul li,
.panel-housing-living #housing-content-main #housing-content-body-2-right .panel-pane ul li,
.panel-housing-living #housing-content-main #housing-content-body-3-right .panel-pane ul li,
.panel-housing-living #housing-content-main #housing-content-body-4-right .panel-pane ul li {
  padding-bottom: 2rem; }

@media screen and (min-width: 992px) {
  .no-js .panel-housing-home #housing-home-content-top .container-fluid div.container-block {
    width: 28% !important; } }

.no-js #application-process-form {
  display: none; }

.no-js .panel-hero-3col .pane-housing-options-panel-pane-2 #housing-image-slideshow {
  display: none !important; }

.no-js .panel-hero-3col .pane-housing-options-panel-pane-2 #housing-image-control {
  display: none !important; }

/**
 * Navigation
 */
#primary-nav ul li.level-1 {
  white-space: nowrap;
  /*  width: auto;*/
  padding: 1rem 0rem;
  width: 34%;
  font-size: 12.5px; }
  @media screen and (min-width: 992px) {
    #primary-nav ul li.level-1 {
      font-size: 15px; } }
  @media screen and (min-width: 1200px) {
    #primary-nav ul li.level-1 {
      font-size: 16px; } }
  #primary-nav ul li.level-1 .active-trail {
    color: #B31B1B; }

#primary-nav ul li:nth-child(2),
#primary-nav ul li:nth-child(3) {
  width: 21%; }

#primary-nav ul li:nth-child(4),
#primary-nav ul li:nth-child(5) {
  width: 12%; }

.information-column .pane-wcmc-contact-pane {
  padding-top: 3rem; }

.information-column .pane-wcm-cta .wcm-cta .wcm-cta__detail .cta-icon-pay + span,
.information-column .pane-wcm-cta .wcm-cta .wcm-cta__detail .cta-icon-feedback + span {
  line-height: 1.2; }

.information-column .pane-wcm-cta .wcm-cta .wcm-cta__detail .cta-icon-email + span,
.information-column .pane-wcm-cta .wcm-cta .wcm-cta__detail .cta-icon-help + span {
  line-height: 2.2; }

.information-column .fieldable-panels-pane .maintenance-request-container {
  padding-top: 1rem; }

footer {
  padding-top: 0; }
  footer .footer-contact .footer-contact__site-name a {
    font-size: 15px; }
    @media screen and (min-width: 992px) {
      footer .footer-contact .footer-contact__site-name a {
        font-size: 18px; } }
  footer .footer-site .footer-address {
    border-right: none; }
    @media screen and (min-width: 768px) {
      footer .footer-site .footer-address {
        width: 24% !important; } }
    @media screen and (min-width: 992px) {
      footer .footer-site .footer-address {
        width: 20% !important; } }
  footer .footer-site .footer-nav {
    margin-bottom: 2rem;
    border-left: 1px solid #DDD; }
    @media screen and (min-width: 768px) {
      footer .footer-site .footer-nav {
        width: 75% !important; } }
    @media screen and (min-width: 992px) {
      footer .footer-site .footer-nav {
        width: 79% !important; } }
    footer .footer-site .footer-nav .block {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      -ms-column-count: 3;
      -o-column-count: 3;
      column-count: 3;
      -webkit-column-gap: 2rem;
      -moz-column-gap: 2rem;
      -ms-column-gap: 2rem;
      -o-column-gap: 2rem;
      column-gap: 2rem; }
      footer .footer-site .footer-nav .block ul.menu {
        width: 100%; }
        @media screen and (min-width: 992px) {
          footer .footer-site .footer-nav .block ul.menu {
            width: 85%; } }
        footer .footer-site .footer-nav .block ul.menu li {
          width: 95%; }
        footer .footer-site .footer-nav .block ul.menu > li:nth-child(1),
        footer .footer-site .footer-nav .block ul.menu > li:nth-child(2),
        footer .footer-site .footer-nav .block ul.menu > li:nth-child(4) {
          display: inline-block; }

/**
 * global classes
 */
#navbar-bar,
#navbar-tray {
  z-index: 2002; }

.slideup, .slidedown {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out; }

.slidedown {
  max-height: 150rem; }

.layover-fadein {
  max-height: 25.5rem;
  z-index: 2001;
  overflow: hidden;
  -webkit-transition: max-height 0.5s ease-in-out;
  -moz-transition: max-height 0.5s ease-in-out;
  -ms-transition: max-height 0.5s ease-in-out;
  -o-transition: max-height 0.5s ease-in-out;
  transition: max-height 0.5s ease-in-out; }

.layover-fadeout {
  max-height: 0;
  z-index: -1;
  overflow: hidden; }

.fieldable-panels-pane .incoming-residents-3col-wrapper {
  display: block;
  margin-bottom: 0rem; }
  @media screen and (min-width: 992px) {
    .fieldable-panels-pane .incoming-residents-3col-wrapper {
      height: 18rem; }
      .fieldable-panels-pane .incoming-residents-3col-wrapper div.col-md-4 {
        padding: 0 1rem; }
      .fieldable-panels-pane .incoming-residents-3col-wrapper div.col-md-4:first-child {
        padding: 0 1rem 0 0; }
      .fieldable-panels-pane .incoming-residents-3col-wrapper div.col-md-4:last-child {
        padding: 0 0 0 1rem; } }
  @media screen and (min-width: 1200px) {
    .fieldable-panels-pane .incoming-residents-3col-wrapper {
      height: 20rem; }
      .fieldable-panels-pane .incoming-residents-3col-wrapper div.col-md-4 {
        padding: 0 1rem; }
      .fieldable-panels-pane .incoming-residents-3col-wrapper div.col-md-4:first-child {
        padding: 0 1rem 0 0; }
      .fieldable-panels-pane .incoming-residents-3col-wrapper div.col-md-4:last-child {
        padding: 0 0 0 1rem; } }
  .fieldable-panels-pane .incoming-residents-3col-wrapper .incoming-residents-box {
    position: relative;
    text-align: center; }
    @media screen and (min-width: 992px) {
      .fieldable-panels-pane .incoming-residents-3col-wrapper .incoming-residents-box {
        max-width: 34rem; } }
    .fieldable-panels-pane .incoming-residents-3col-wrapper .incoming-residents-box p.incoming-residents-text {
      position: absolute;
      top: 44%;
      left: 0;
      right: 0;
      color: #fff;
      font-family: '1898Sans-Regular';
      font-size: 2rem;
      z-index: 2;
      text-align: center; }
      .fieldable-panels-pane .incoming-residents-3col-wrapper .incoming-residents-box p.incoming-residents-text .glyphicon {
        top: 0;
        font-size: 1.2rem; }
    .fieldable-panels-pane .incoming-residents-3col-wrapper .incoming-residents-box p.incoming-residents-layer {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      z-index: 1; }
    .fieldable-panels-pane .incoming-residents-3col-wrapper .incoming-residents-box img {
      height: auto; }
      @media screen and (min-width: 992px) {
        .fieldable-panels-pane .incoming-residents-3col-wrapper .incoming-residents-box img {
          height: 15rem; } }
      @media screen and (min-width: 1200px) {
        .fieldable-panels-pane .incoming-residents-3col-wrapper .incoming-residents-box img {
          height: 18rem; } }
    .fieldable-panels-pane .incoming-residents-3col-wrapper .incoming-residents-box:hover p.incoming-residents-text .glyphicon {
      left: 4px; }

.fieldable-panels-pane .housing-second-nav-container .row {
  padding-bottom: 1rem; }
  .fieldable-panels-pane .housing-second-nav-container .row h3 {
    min-height: 3.8rem;
    line-height: 1.4; }
    .fieldable-panels-pane .housing-second-nav-container .row h3 .glyphicon:before {
      color: #e7751d; }
  .fieldable-panels-pane .housing-second-nav-container .row a {
    outline: none; }
    .fieldable-panels-pane .housing-second-nav-container .row a:hover h3 {
      color: #e7751d; }

.icon-amenities-2:before {
  content: '';
  position: absolute;
  background: transparent url(../images/sprite/housing-icons.png) no-repeat;
  background-position: 3px, -2px;
  background-size: 58rem;
  width: 27px;
  height: 27px;
  opacity: 1;
  left: -30px;
  top: -1px; }

.icon-amenities-4:before {
  content: '';
  position: absolute;
  background: transparent url(../images/sprite/housing-icons.png) no-repeat;
  background-position: -23px, -2px;
  background-size: 58rem;
  width: 27px;
  height: 27px;
  opacity: 1;
  left: -30px;
  top: -1px; }

.icon-amenities-6:before {
  content: '';
  position: absolute;
  background: transparent url(../images/sprite/housing-icons.png) no-repeat;
  background-position: -48px, -2px;
  background-size: 58rem;
  width: 27px;
  height: 27px;
  opacity: 1;
  left: -30px;
  top: -1px; }

.icon-amenities-1:before {
  content: '';
  position: absolute;
  background: transparent url(../images/sprite/housing-icons.png) no-repeat;
  background-position: -72px, -2px;
  background-size: 58rem;
  width: 27px;
  height: 27px;
  opacity: 1;
  left: -30px;
  top: -1px; }

.icon-amenities-7:before {
  content: '';
  position: absolute;
  background: transparent url(../images/sprite/housing-icons.png) no-repeat;
  background-position: -98px, -2px;
  background-size: 58rem;
  width: 27px;
  height: 27px;
  opacity: 1;
  left: -30px;
  top: -1px; }

.icon-amenities-3:before {
  content: '';
  position: absolute;
  background: transparent url(../images/sprite/housing-icons.png) no-repeat;
  background-position: -123px, -2px;
  background-size: 58rem;
  width: 27px;
  height: 27px;
  opacity: 1;
  left: -30px;
  top: -1px; }

.icon-amenities-9:before {
  content: '';
  position: absolute;
  background: transparent url(../images/sprite/housing-icons.png) no-repeat;
  background-position: -148px, -2px;
  background-size: 58rem;
  width: 27px;
  height: 27px;
  opacity: 1;
  left: -30px;
  top: -1px; }

.icon-amenities-8:before {
  content: '';
  position: absolute;
  background: transparent url(../images/sprite/housing-icons.png) no-repeat;
  background-position: -173px, -2px;
  background-size: 58rem;
  width: 27px;
  height: 27px;
  opacity: 1;
  left: -30px;
  top: -1px; }

.icon-amenities-5:before {
  content: '';
  position: absolute;
  background: transparent url(../images/sprite/housing-icons.png) no-repeat;
  background-position: -196px, -2px;
  background-size: 58rem;
  width: 27px;
  height: 27px;
  opacity: 1;
  left: -30px;
  top: -1px; }

.icon-amenities-18:before {
  content: '';
  position: absolute;
  background: transparent url(../images/sprite/housing-icons.png) no-repeat;
  background-position: -400px, -2px;
  background-size: 58rem;
  width: 27px;
  height: 27px;
  opacity: 1;
  left: -30px;
  top: -1px; }

.icon-amenities-19:before {
  content: '';
  position: absolute;
  background: transparent url(../images/sprite/housing-icons.png) no-repeat;
  background-position: -349px, -2px;
  background-size: 58rem;
  width: 27px;
  height: 27px;
  opacity: 1;
  left: -30px;
  top: -1px; }

.icon-amenities-20:before {
  content: '';
  position: absolute;
  background: transparent url(../images/sprite/housing-icons.png) no-repeat;
  background-position: -525px, -2px;
  background-size: 58rem;
  width: 27px;
  height: 27px;
  opacity: 1;
  left: -30px;
  top: -1px; }

.icon-amenities-21:before {
  content: '';
  position: absolute;
  background: transparent url(../images/sprite/housing-icons.png) no-repeat;
  background-position: -322px, -2px;
  background-size: 58rem;
  width: 27px;
  height: 27px;
  opacity: 1;
  left: -30px;
  top: -1px; }

.icon-amenities-22:before {
  content: '';
  position: absolute;
  background: transparent url(../images/sprite/housing-icons.png) no-repeat;
  background-position: -424px, -2px;
  background-size: 58rem;
  width: 27px;
  height: 27px;
  opacity: 1;
  left: -30px;
  top: -1px; }

.icon-amenities-23:before {
  content: '';
  position: absolute;
  background: transparent url(../images/sprite/housing-icons.png) no-repeat;
  background-position: -525px, -2px;
  background-size: 58rem;
  width: 27px;
  height: 27px;
  opacity: 1;
  left: -30px;
  top: -1px; }

.icon-amenities-24:before {
  content: '';
  position: absolute;
  background: transparent url(../images/sprite/housing-icons.png) no-repeat;
  background-position: -550px, -2px;
  background-size: 58rem;
  width: 27px;
  height: 27px;
  opacity: 1;
  left: -30px;
  top: -1px; }

.icon-amenities-25:before {
  content: '';
  position: absolute;
  background: transparent url(../images/sprite/housing-icons.png) no-repeat;
  background-position: -449px, -2px;
  background-size: 58rem;
  width: 27px;
  height: 27px;
  opacity: 1;
  left: -30px;
  top: -1px; }

.icon-resident-type:before {
  content: '';
  position: absolute;
  background: transparent url(../images/sprite/housing-icons.png) no-repeat;
  background-position: -299px, -2px;
  background-size: 58rem;
  width: 27px;
  height: 27px;
  opacity: 1;
  left: -30px;
  top: -1px; }

.icon-cost:before {
  content: '';
  position: absolute;
  background: transparent url(../images/sprite/housing-icons.png) no-repeat;
  background-position: -249px, -2px;
  background-size: 58rem;
  width: 27px;
  height: 27px;
  opacity: 1;
  left: -30px;
  top: -1px; }

.icon-location:before {
  content: '';
  position: absolute;
  background: transparent url(../images/sprite/housing-icons.png) no-repeat;
  background-position: -223px, -2px;
  background-size: 58rem;
  width: 27px;
  height: 27px;
  opacity: 1;
  left: -30px;
  top: -1px; }

.icon-floorplan:before {
  content: '';
  position: absolute;
  background: transparent url(../images/sprite/housing-icons.png) no-repeat;
  background-position: -273px, -2px;
  background-size: 58rem;
  width: 27px;
  height: 27px;
  opacity: 1;
  left: -30px;
  top: -1px; }

.glyphicon-triangle-right:before {
  content: "\e250"; }

.glyphicon-triangle-left:before {
  content: '\e251'; }

.glyphicon-triangle-top:before {
  content: '\e253'; }

.glyphicon-triangle-bottom:before {
  content: '\e252'; }

#application-process {
  position: relative; }
  #application-process .wcm-cta-item {
    z-index: 1; }
  #application-process #application-process-form {
    position: absolute;
    top: 0;
    left: 0; }
    #application-process #application-process-form #housing-panes-form {
      position: relative;
      background: #f7f7f6;
      padding: 2.3rem;
      border: 1px solid #eee; }
      #application-process #application-process-form #housing-panes-form .form-button-close {
        position: absolute;
        top: 0.5rem;
        right: 1rem;
        cursor: pointer;
        font-family: 'Glyphicons Halflings';
        font-style: normal;
        font-weight: 400;
        font-size: 1.8rem;
        text-shadow: 0 1px 0 #fff;
        color: #555; }
        #application-process #application-process-form #housing-panes-form .form-button-close:hover {
          color: #e7751d;
          text-decoration: none; }
        #application-process #application-process-form #housing-panes-form .form-button-close:after {
          content: '\e014';
          /* X symbol */ }
      #application-process #application-process-form #housing-panes-form .form-item.form-type-radios > label {
        font-size: 1.7rem;
        font-weight: 400;
        padding: 0.4rem 0;
        color: #cf4520; }
        @media screen and (min-width: 1200px) {
          #application-process #application-process-form #housing-panes-form .form-item.form-type-radios > label {
            font-size: 1.9rem; } }
      #application-process #application-process-form #housing-panes-form .form-item.form-type-radios .form-radios {
        padding-right: 1rem;
        margin-bottom: 1.8rem; }
        #application-process #application-process-form #housing-panes-form .form-item.form-type-radios .form-radios .form-radio {
          display: none; }
        #application-process #application-process-form #housing-panes-form .form-item.form-type-radios .form-radios .form-type-radio {
          display: block;
          background-image: url("../images/radios-small.png");
          width: 22px;
          height: 22px;
          cursor: pointer;
          background-position: 0px 0px;
          margin-right: 4rem;
          margin-bottom: 1.4rem;
          min-height: 22px; }
          #application-process #application-process-form #housing-panes-form .form-item.form-type-radios .form-radios .form-type-radio label {
            margin-left: 0.8rem;
            text-align: left;
            max-width: none;
            font-size: 1.4rem;
            width: 20rem;
            vertical-align: middle;
            display: table; }
            @media screen and (min-width: 1200px) {
              #application-process #application-process-form #housing-panes-form .form-item.form-type-radios .form-radios .form-type-radio label {
                font-size: 1.5rem; } }
        #application-process #application-process-form #housing-panes-form .form-item.form-type-radios .form-radios .form-type-radio-checked {
          background-position: 0px -22px; }

.pane-front-cta-pane #application-process #application-process-form {
  width: 90%;
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-box-shadow: 3px 3px 30px 3px #aaa;
  -moz-box-shadow: 3px 3px 30px 3px #aaa;
  -ms-box-shadow: 3px 3px 30px 3px #aaa;
  -o-box-shadow: 3px 3px 30px 3px #aaa;
  box-shadow: 3px 3px 30px 3px #aaa;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -webkit-border-bottom-right-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-topright: 10px;
  -moz-border-radius-bottomright: 10px;
  -moz-border-radius-bottomleft: 10px;
  -ms-border-top-left-radius: 10px;
  -ms-border-top-right-radius: 10px;
  -ms-border-bottom-right-radius: 10px;
  -ms-border-bottom-left-radius: 10px;
  -o-border-top-left-radius: 10px;
  -o-border-top-right-radius: 10px;
  -o-border-bottom-right-radius: 10px;
  -o-border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px; }
  @media screen and (min-width: 992px) {
    .pane-front-cta-pane #application-process #application-process-form {
      width: 100%;
      top: 3.5%; } }
  @media screen and (min-width: 1200px) {
    .pane-front-cta-pane #application-process #application-process-form {
      top: 6.2%; } }
  .pane-front-cta-pane #application-process #application-process-form #housing-panes-form {
    text-align: left;
    padding: 1rem;
    min-width: 22rem;
    min-height: 22rem; }
    @media screen and (min-width: 992px) {
      .pane-front-cta-pane #application-process #application-process-form #housing-panes-form {
        padding: 1.5rem; } }
    @media screen and (min-width: 1200px) {
      .pane-front-cta-pane #application-process #application-process-form #housing-panes-form {
        min-width: 25.4rem;
        min-height: 25.4rem;
        padding: 2rem; } }

.pane-front-cta-pane a {
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .pane-front-cta-pane a:focus {
    outline: 0; }
  .pane-front-cta-pane a -moz-focus-inner {
    border: 0; }

.pane-wcm-cta #application-process .wcm-cta__tile {
  border-right: none; }

.pane-wcm-cta #application-process #application-process-form {
  top: -1.8rem;
  left: -1.5rem; }
  .pane-wcm-cta #application-process #application-process-form #housing-panes-form {
    min-width: 25rem;
    min-height: 25rem;
    font-size: 1.8rem;
    -webkit-border-top-left-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    -webkit-border-bottom-right-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-topright: 10px;
    -moz-border-radius-bottomright: 10px;
    -moz-border-radius-bottomleft: 10px;
    -ms-border-top-left-radius: 10px;
    -ms-border-top-right-radius: 10px;
    -ms-border-bottom-right-radius: 10px;
    -ms-border-bottom-left-radius: 10px;
    -o-border-top-left-radius: 10px;
    -o-border-top-right-radius: 10px;
    -o-border-bottom-right-radius: 10px;
    -o-border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px; }

.pane-wcm-cta a {
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .pane-wcm-cta a:focus {
    outline: 0; }
  .pane-wcm-cta a -moz-focus-inner {
    border: 0; }
