/**
 * Full Hero 3 Column layout
 */
.panel-fullhero-3col {
  .main-content {
    padding-bottom: 4rem;
  }
  // housing options list view begin
  .pane-housing-options-panel-pane-1 {
    .views-row-first {
      border-top: 1px solid #eee;
    }
    .views-row {
    	position: relative;
      @include breakpoint($md) {
        border-right: 1.5rem solid #f7f7f7;
        border-top: none;
        &:before {
          content: '';
          display: block;
          position: absolute;
          right: -1.8rem;
          top: 45%;
          width: 0;
          height: 0;
          border-top: 1rem solid transparent;
          border-right: 1rem solid transparent;
          border-bottom: 1rem solid transparent;
          border-left: 1rem solid #fff;
        }
        &:after {
          content: '';
          display: block;
          position: absolute;
          right: -3.4rem;
          top: 45%;
          width: 0;
          height: 0;
          border-top: 1rem solid transparent;
          border-right: 1rem solid transparent;
          border-bottom: 1rem solid transparent;
          border-left: 1rem solid #f7f7f7;
        }
      }
      &:hover {
        @include breakpoint($md) {
          background-color: #f7f7f7;
          border-right-color: $wcm-dark-orange;
          &:before {
            border-left: 1rem solid #f7f7f7;
          }
          &:after {
            border-left: 1rem solid $wcm-dark-orange;
          }
          @include custom-tranisition(all 0.3s ease-in);
        }
        .views-field-nothing {
          .row {
            .housing-image {
              .housing-hero-image-overlay {
                opacity: 0.6;
                @include custom-tranisition(opacity 0.3s ease-in);
              }
              .btn-view-this-property {
                opacity: 1;
                @include custom-tranisition(opacity 0.3s ease-in);
              }
            }
          }
        }
      }
      div.views-field-nothing {
        padding-bottom: 2rem;
        border-bottom: 1px solid #efefef;
        a {
          color: $wcm-dark-gray;
          outline: none;
          &:hover {
            text-decoration: none;
          }
          .views-field-title {
            padding: 3rem 0 2rem 2rem;
            @include breakpoint($md) {
              padding: 2.5rem 0 0 2rem;
            }
            h3 {
              margin: 0;
            }
          }
        }
        .row {
          padding: 0;
          @include breakpoint($md) {
            padding: 2rem 4rem 0rem 4rem;;
          }
          .housing-image {
            position: relative;
            padding: 0;
            .housing-hero-image-overlay {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: #000;
              opacity: 0;
            }
            .btn-view-this-property {
              position: absolute;
              margin: 0 auto;
              top: 45%;
              left: 30%;
              right: 30%;
              font-size: 1.2rem;
              padding: 3px 15px;
              opacity: 0;
              @include breakpoint($md) {
                left: 25%;
                right: 25%;
                top: 43%;
              }
              @include breakpoint($lg) {
                font-size: 1.3rem;
              }
            }
            + .col-md-4 {
              padding-right: 4rem;
            }
            img {
              width: 100%;
            }
          }
          .housing-location,
          .housing-resident-type,
          .housing-cost {
            position: relative;
          }
          .housing-resident-type,
          .housing-cost {
            border-top: 1px solid #efefef;
            padding-top: 2rem;
            margin-top: 2rem;
          }
          .housing-location {
            padding-top: 2rem;
            @include breakpoint($md) {
              padding-top: 0;
            }
          }
          .housing-cost {
            padding-bottom: 2rem;
            @include breakpoint($md) {
              padding-bottom: 0;
            }
          }
          .housing-amenities {
            ul {
              padding: 0.5rem 3rem;
              @include breakpoint($md) {
                padding: 0;
              }
              li {
                position: relative;
                padding: 0 1rem 2rem 0.5rem;
              }
            }
          }
        }
      }
      @include custom-ul-list-style;
    }
  }
  // housing options list view end
  .pane-news-panel-pane-5,
  .pane-news-panel-pane-6 {
    margin-top: 6.5rem;
    clear: both;
    .pane-title {
      margin-bottom: 0;
    }
    div.views-row {
      padding: 3rem 0;
      clear: both;
      &:not(:last-child) {
        border-bottom: 1px solid #ddd;
      }
      .teaser-image {
        @include breakpoint($sm) {
          float: left;
          width: 25%;
          margin-right: 2.4rem;
        }
        img {
          height: auto;
          width: 100%;
          max-width: 100%;
        }
      }
      .views-field-nothing {
        .teaser-title {
          padding-top: 1rem;
          @include breakpoint($sm) {
            padding-top: 0;
          }
        }
        .post-date {
          font-family: "1898Sans-Bold";
          font-size: 1.2rem;
          letter-spacing: 0.8px;
          text-transform: uppercase;
          color: #626262;
          margin: 1.2rem 0;
        }
      }
      h2, h3, h4, h5 {
        margin-top: 0;
      }
    }
    .views-row-first {
      padding-top: 4rem;
    }
    .view-footer {
      padding-top: 1rem;
      @include breakpoint($md) {
        float: left;
      }
    }
  }
  //
  .housing-callout-container {
    position: relative;
    .housing-callout-link {
      .housing-callout-image {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 94%;
          height: 100%;
          background: #000;
          opacity: .2;
          background-size: 100% 100%;
        }
        img {
          width: 94%;
        }
      }
      .housing-callout-layover {
        position: absolute;
        font-family: '1898Sans-Regular';
        font-size: 3rem;
        color: #fff;
        font-weight: 600;
        top: 42%;
        left: 0;
        right: 0;
        text-align: center;
        @include breakpoint($sm) {
          font-size: 4rem;
        }
        .glyphicon-triangle-right {
          font-size: 2rem;
          @include breakpoint($sm) {
            font-size: 3rem;
          }
        }
      }
      &:hover {
        .housing-callout-layover {
          color: $wcm-bright-orange;
          .glyphicon-triangle-right {
            @include custom-animation(horizontal 2s 1);
          }
        }
      }
    }
  }
  .housing-second-nav-container {
    .glyphicon {
      &:before {
        font-size: 1.5rem;
      }
    }
  }
}
