$prefixes: -webkit-, -moz-, -ms-, -o-, "";

@mixin custom-multi-column($count: 3) {
  @each $prefix in $prefixes {
    #{$prefix}column-count: $count;
  }
}

@mixin custom-multi-gap($width: 2rem) {
  @each $prefix in $prefixes {
    #{$prefix}column-gap: $width;
  }
}

@mixin custom-cover-bg-image($x-pos: 50%, $y-pos: 0%) {
  background-repeat: no-repeat;
  background-position: $x-pos, $y-pos;
  @each $prefix in $prefixes {
    #{$prefix}background-size: cover;
  }
}

@mixin custom-linear-gradient-bg-image($from-dir:top, $to-dir:bottom, $from-color:#000, $to-color:#000, $from-opacity:0.5, $to-opacity:0.5) {
  @each $prefix in $prefixes {
    @if $prefix == "-webkit-" {
      background-image: -webkit-gradient(linear, left $from-dir, left $to-dir, color-stop(0%, rgba($from-color, $from-opacity)), color-stop(100%, rgba($to-color, $to-opacity))); /* Chrome,Safari4+ */
      background-image: -webkit-linear-gradient($from-dir, rgba($from-color, $from-opacity) 0%, rgba($to-color, $to-opacity) 100%); /* Chrome10+,Safari5.1+ */
    }
    @else if $prefix == "" {
      background-image: linear-gradient(to $to-dir, rgba($from-color, $from-opacity) 0%, rgba($to-color, $to-opacity) 100%); /* W3C */
    }
    @else {
      background-image: #{$prefix}linear-gradient($from-dir, rgba($from-color, $from-opacity) 0%, rgba($to-color, $to-opacity) 100%);
    }
  }
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$from-color}', endColorstr='#{$to-color}', GradientType='0'); /* IE6-9 */
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$from-color}', endColorstr='#{$to-color}', gradientType='0')";
}

@mixin custom-tranisition($params...) {
  @each $prefix in $prefixes {
    #{$prefix}transition: $params;
  }
}

@mixin custom-border-radius($size...) {
  @if length($size) == 1 {
    @each $prefix in $prefixes {
      #{$prefix}border-radius: $size;
    }
  } @else {
    @include custom-border-radius-ext($size...);
  }
}

@mixin custom-border-radius-ext($top-l: 0, $top-r: 0, $bottom-r: 0, $bottom-l: 0) {
  @each $prefix in $prefixes {
    @if $prefix == "-moz-" {
      @if $top-l != 0 { -moz-border-radius-topleft: $top-l; }
      @if $top-r != 0 { -moz-border-radius-topright: $top-r; }
      @if $bottom-r != 0 { -moz-border-radius-bottomright: $bottom-r; }
      @if $bottom-l != 0 { -moz-border-radius-bottomleft: $bottom-l; }
    } @else {
      @if $top-l != 0 { #{$prefix}border-top-left-radius: $top-l; }
      @if $top-r != 0 { #{$prefix}border-top-right-radius: $top-r; }
      @if $bottom-r != 0 { #{$prefix}border-bottom-right-radius: $bottom-r; }
      @if $bottom-l != 0 { #{$prefix}border-bottom-left-radius: $bottom-l; }
    }
  }
}

@mixin custom-opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin custom-ul-list-style($style: none, $padding: 0, $margin: 0) {
  ul {
    list-style: $style;
    padding: $padding;
    li {
      margin: $margin;
    }
  }
}

@mixin custom-box-spacing($target: div, $bt-column: col-md-4, $padding: padding, $width: 1rem) {
  #{$target}.#{$bt-column} {
    #{$padding}: 0 $width;
  }
  #{$target}.#{$bt-column}:first-child {
    padding: 0 $width 0 0;
  }
  #{$target}.#{$bt-column}:last-child {
    padding: 0 0 0 $width;
  }
}

@mixin custom-filter($filter-type, $filter-amount) {
  @each $prefix in $prefixes {
    #{$prefix}filter: $filter-type+unquote('(#{$filter-amount})');
  }
}

@mixin custom-box-shadow($params...) {
  @each $prefix in $prefixes {
    #{$prefix}box-shadow: $params;
  }
}

@mixin custom-justify-center($position: center) {
  display: -webkit-box; // OLD - iOS 6-, Safari 3.1-6
  display: -moz-box;    // OLD - Firefox 19- (buggy but mostly works)
  display: -ms-flexbox; // TWEENER - IE 10
  display: -webkit-flex;// NEW - Chrome
  display: flex;
  -webkit-justify-content: $position;
  -ms-flex-pack: $position;
  justify-content: $position;
  -webkit-align-items: $position;
  -ms-flex-align: $position;
  align-items: $position;
}

@mixin custom-keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@include custom-keyframes(horizontal) {
  0%, 12%, 24%, 36%, 100% {
    @each $prefix in $prefixes {
      #{$prefix}transform: translate(0, 0);
    }
  }
  6%, 18%, 30% {
    @each $prefix in $prefixes {
      #{$prefix}transform: translate(5px, 0);
    }
  }
}

@include custom-keyframes(vertical) {
  0%, 12%, 24%, 36%, 100% {
    @each $prefix in $prefixes {
      #{$prefix}transform: translate(0, 0);
    }
  }
  6%, 18%, 30% {
    @each $prefix in $prefixes {
      #{$prefix}transform: translate(0, 5px);
    }
  }
}

@mixin custom-animation($params...) {
  @each $prefix in $prefixes {
    #{$prefix}animation: $params;
  }
}

@function calculate-rem($size) {
  $rem-size: $size / 16px;
  @return $rem-size * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculate-rem($size);
}
