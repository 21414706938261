.panel-twocol {
  .main-content {
    padding-bottom: 4rem;
    // news page begin
    .news-featured-image {
      float: left;
      padding-right: 3rem;
      .hero-image {
        img {
          max-width: 45rem;
        }
      }
    }
    .pane-node-field-news-category {
      ul {
        li {
          a {
            color: #cf4520;
            font-size: 14px;
            background: url(../images/tag.png) 0 0 no-repeat;
            background-size: 16px 16px;
            padding-left: 25px;
            position: relative;
          }
        }
      }
    }
    // news page end
  }
}
