/**
 * Homepage layout
 */

.panel-housing-home {
  .container-fluid {
    padding: 0;
    .row {
      margin: 0;
    }
  }
  .pane-front-cta-pane {
    padding-top: 1rem;
    @include breakpoint($md) {
      padding-top: 0;
    }
    .front-cta-pane {
      background: #eee;
      .row {
        max-width: 100%;
        margin: 0 auto;
        @include breakpoint($md) {
          max-width: 90%;
        }
        .wcm-cta-wrapper {
          display: inline-block;
          text-align: center;
          padding: 1.5rem;
          @include breakpoint($md) {
            padding: 0;
          }
          a.wcm-cta-item {
            text-align: center;
            display: inline-block;
            padding: 3rem 2rem 1rem 2rem;
            width: 88%;
            height: 18rem;
            @include custom-border-radius(10px, 10px, 10px, 10px);
            @include breakpoint($md) {
              padding: 5rem 2rem 3rem 2rem;
              margin: 1rem;
              width: 23rem;
              height: 23rem;
            }
            @include breakpoint($lg) {
              padding: 6rem 0;
              margin: 2rem 0;
              width: 25rem;
              height: 25rem;
            }
            &:hover {
              background: #fff;
              text-decoration: none;
              @include custom-box-shadow(3px 3px 30px 3px #929292);
              @include custom-tranisition(box-shadow 0.3s ease-in);
            }
            .cta-icon {
              width: 6.4rem;
              height: 6.4rem;
              color: #b31b1b;
              fill: #e97623;
              margin-bottom: 2rem;
            }
            .wcm-cta-callout-title {
              display: block;
              font-size: 1.9rem;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
  #housing-home-content-footer {
    .panel-pane {
      p {
        padding: 0;
        margin: 0;
      }
      .living-in-nyc-container {
        position: relative;
        margin-top: 1.2rem;
        display: block;
        &:hover {
          .living-in-nyc-title {
            color: $wcm-bright-orange;
            .glyphicon-triangle-right {
              @include custom-animation(horizontal 2s 1);
            }
          }
        }
        .living-in-nyc-title {
          position: absolute;
          font-family: '1898Sans-Regular';
          font-weight: 800;
          color: #efefef;
          z-index: 2;
          top: 47%;
          left: 15%;
          font-size: 4rem;
          @include breakpoint($sm) {
            left: 38%;
          }
          .glyphicon-triangle-right {
            left: -5px;
            &:before {
              font-size: 2.7rem;
            }
          }
        }
        .living-in-nyc-img {
          width: 100%;
          height: 55rem;
          background-image: url("/sites/default/files/styles/panopoly_image_original/public/living-in-nyc.jpg");
          @include custom-cover-bg-image;
          z-index: 0;
          &:before {
            content: ' ';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0.8;
            @include custom-linear-gradient-bg-image(top, bottom, $wcm-black, $wcm-black, 0.2, 0.2);
            z-index: 1;
            will-change: transform;
          }
        }
      }
    }
  }
}

// homepage slides
#housing-home-content-header {
  margin-top: -1px;
}
#housing-home-content-top {
  display: block;
  text-align: center;
  max-width: 160rem;
  width: 100%;
  margin: 0 auto;
  .container-fluid {
    height: 100%;
    @include breakpoint($md) {
      height: 64rem;
    }
    div.container-block {
      width: 100%;
      display: block;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      text-align:center;
      @include custom-tranisition(width 0.5s ease);
      @include breakpoint($md) {
        display: inline-block;
        width: 32.9%;
        &:not(:last-child) {
          border-right: 9px solid #fff;
        }
      }
      &:hover {
        @include breakpoint($md) {
          width: 40%;
        }
      }
      .panel-pane {
        .fieldable-panels-pane {
          position: relative;
          .container-wrapper {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            //opacity: .6;
            //mix-blend-mode: multiply;
            z-index: 0;
            @include breakpoint($md) {
              @include custom-tranisition(top 0.5s, bottom 0.5s);
            }
          }
          .container-layer {
            position: absolute;
            top: 100%;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #fff; // #f0f0f0;
            opacity: .9;
            width: 100%;
            height: 100%;
            z-index: 2;
            @include breakpoint($md) {
              @include custom-tranisition(top 0.5s, bottom 0.5s);
            }
          }
          .container-title {
            position: absolute;
            top: 30%;
            left: 16%;
            color: #fff;
            width: 22rem;
            font-family: '1898Sans-Regular';
            font-size: 2.8rem;
            font-weight: 600;
            line-height: 1.2;
            text-align: left;
            max-width: 32rem;
            z-index: 2;
            opacity: 1;
            // @include breakpoint($xs) {
            //   top: 25%;
            // }
            @include breakpoint($sm) {
              font-size: 3.4rem;
              top: 35%;
              left: 25%;
            }
            @include breakpoint($md) {
              top: 70%;
              left: 12%;
              @include custom-tranisition(all 0.5s ease);
            }
            .container-description {
              font-size: 1.4rem;
              font-weight: 400;
              line-height: 1.3;
              padding-top: 1rem;
              width: 25rem;
              @include breakpoint($sm) {
                padding-top: 2rem;
                line-height: 1.4;
                width: 28rem;
              }
              @include breakpoint($md) {
                opacity: 0;
                font-size: 1.8rem;
                padding-top: 2.6rem;
              }
            }
            .glyphicon {
              font-size: 1.4rem;
              color: $wcm-bright-orange;
            }
          }
          &:hover {
            @include breakpoint($md) {
              .container-wrapper {
                bottom: 100%;
                @include custom-tranisition(top 0.5s, bottom 0.5s);
              }
              .container-layer {
                top: 0;
                @include custom-tranisition(top 0.5s, bottom 0.5s);
              }
              .container-title {
                position: absolute;
                top: 35%;
                left: 18%;
                color: $wcm-dark-orange;
                @include custom-tranisition(all 0.5s ease);
                .container-description {
                  opacity: 1;
                  @include custom-tranisition(opacity 0.5s ease);
                }
              }
            }
          }
          img {
            max-width: 100%;
            height: auto;
            margin: 0;
            z-index: 0;
            //@include custom-filter(blur, 0.4px);
            @include breakpoint($md) {
              max-width: none;
              max-height: 63rem;
              margin: 0 -100%;
            }
          }
        }
      }
      p {
        margin: 0;
      }
    }
  }
}

#housing-home-content-footer {
  .panel-pane {
    .fieldable-panels-pane {
      margin-bottom: -1px;
    }
  }
}
// #housing-home-content-header-left .panel-pane .container-wrapper {
//   background-color: $hero-bg-left;
// }
//
// #housing-home-content-header-center .panel-pane .container-wrapper {
//   background-color: $hero-bg-center;
// }
//
// #housing-home-content-header-right .panel-pane .container-wrapper {
//   background-color: $hero-bg-right;
// }
