
.no-js {
  .panel-housing-home {
    #housing-home-content-top {
      .container-fluid {
        div.container-block {
          @include breakpoint($md) {
            width: 28% !important;
          }
        }
      }
    }
  }
  #application-process-form {
    display: none;
  }
  .panel-hero-3col {
    .pane-housing-options-panel-pane-2 {
      #housing-image-slideshow {
        display: none !important;
      }
      #housing-image-control {
        display: none !important;
      }
    }
  }
}
